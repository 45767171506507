@font-face {
  font-family: 'icomoon';
  src: url('/themes/sherman/assets/dist/fonts/icomoon.eot?9jcu83');
  src: url('/themes/sherman/assets/dist/fonts/icomoon.eot?9jcu83#iefix') format('embedded-opentype'), url('/themes/sherman/assets/dist/fonts/icomoon.ttf?9jcu83') format('truetype'), url('/themes/sherman/assets/dist/fonts/icomoon.woff?9jcu83') format('woff'), url('/themes/sherman/assets/dist/fonts/icomoon.svg?9jcu83#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add52:before {
  content: "\e900";
}

.icon-agreement2:before {
  content: "\e901";
}

.icon-building104:before {
  content: "\e902";
}

.icon-building160:before {
  content: "\e903";
}

.icon-drawing8:before {
  content: "\e904";
}

.icon-hospital35:before {
  content: "\e905";
}

.icon-inspiration:before {
  content: "\e906";
}

.icon-justice4:before {
  content: "\e907";
}

.icon-liquid15:before {
  content: "\e908";
}

.icon-loudspeaker27:before {
  content: "\e909";
}

.icon-management4:before {
  content: "\e90a";
}

.icon-police22:before {
  content: "\e90b";
}

.icon-progress:before {
  content: "\e90c";
}

.icon-satellitedish:before {
  content: "\e90d";
}

.icon-share53:before {
  content: "\e90e";
}


* {
    word-break: normal !important;
}
// Header
header {
    z-index: 1000;
}
//Logo
.kode_logo {
    padding: 0;

    img {
        //IE needs a defined width and height for SVG elements
        width: 277px;
        height: 114px;
    }
}

.kode_top_strip {
    a {
        color: #c8c8c8;
        text-decoration: none;

        .fa {
            padding-right: 5px;
            color: #fff;
        }

        &:visited {
            color: #c8c8c8;
        }

        &:hover,
        &:focus {
            color: #293f9b;
        }
    }
}

//Primary Nav
.kode_ui_element {
    margin-right: 0;

    @media (min-width: 992px) {
        margin-right: -60px;
    }
}

.kode_search .search-area {
    input {
        float: left;
        max-width: calc(100% - 45px);
        height: 38px;
    }
}

//Banner Styles
.subpage .kode_about_bg {
    min-height: 150px;
    background-attachment: scroll !important;
    background-position: center center !important;
    background-size: cover !important;

    @media (min-width: 992px) {
        min-height: 288px;
    }

    &:before {
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.subpage .kode_aboutus_wrap {
    padding: 36px 0 0;

    @media (min-width: 992px) {
        padding: 182px 0 0;
    }
}

.homepage {
    .cycle-slideshow {
        display: none;

        @media (min-width: 992px) {
            display: block;
            border-top: 120px solid #000;
        }
    }

    .kode_about_bg {
        min-height: 706px;
        background-attachment: scroll !important;

        &:before {
            background-color: rgba(0, 0, 0, 0.25);
        }

        .container {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .kode_breaking_hdg {
        position: absolute;
        bottom: 0;
        height: 100%;

        &:before {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        }

        h6 {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .kode_about_welcome {
        padding-bottom: 0;
    }
}

.kode_breaking_news {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    //   height: 57px;

    @media (max-width: 767px) {
        display: block;
    }

    @media (min-width: 768px) {
        height: 57px;
    }

    h6 {
        color: #293f9b;
    }
}

.kode_news_des .newsBlurb {
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    height: 149px;
}

.kode_breaking_news+ header {
    @media (max-width: 979px) and (min-width: 768px) {
        margin-top: 0;
    }

    @media (min-width: 992px) {
        margin-top: 57px;
    }
}

.kode_brekg_news_des {
    @media (min-width: 768px) {
        position: relative;
        top: 50%;
        padding-top: 0;
        padding-bottom: 0;
        background: transparent;
        transform: translateY(-50%);
    }
}

.kode_search {
    .search-fld {
        display: inline-block;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;

        &:hover {
            color: #fff;
        }
    }

    &:hover .search-fld {
        color: #fff;
    }
}

//Homepage Content
.kode_press_news {
    margin-bottom: 25px;

    figure {
        background: white;

        @media (max-width: 979px) and (min-width: 768px) {
            height: 274px;
        }

        @media (min-width: 980px) {
            height: 336px;
        }

        img {
            margin: 0;

            @media (max-width: 979px) and (min-width: 768px) {
                position: absolute;
                top: 0;
                left: 0;
            }

            @media (min-width: 980px) {
            }
        }
    }

    .kode_news_des h6 {
        position: relative;
        display: block;
        float: left;
        overflow: hidden;
        margin-bottom: 9px;
        max-width: 100%;
        width: 100%;
        color: #474747;
        text-transform: uppercase;
           text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        font-size: 20px;
        line-height: 18px;

        -o-text-overflow: ellipsis;
    }
}

.kode_news_des h6> a,
.kode_event_des h6> a {
    display: block;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.kode_event_des {
    padding-top: 15px;
}

.kode_event_wrap {
    h6 {
        margin-bottom: 22px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
    }

    button {
        display: inline-block;
        padding: 10px 30px;
        outline: 1px solid #ececec;
        border-left: 4px solid;
        background: transparent;
        color: #999;
        text-transform: uppercase;
        font-weight: bold;
        line-height: normal;
    }

    &:visited h6,
    &:visited button {
        color: #bb0023;
    }

    &:hover h6,
    &:hover button {
        color: #bb0023;
    }
}

//Subpage Banner
a {
    cursor: pointer;

    &:visited {
        color: #bb0023;
    }
}

.kode_purchase_bg {
    padding: 25px 0;

    a.kode_link_2:visited {
        color: white;
    }
}

.kode_bread_crumb {
    .no-link {
        padding: 0 10px;
        color: #f2f2f2;
        text-transform: uppercase;
        font-size: 12px;
    }

    ul li:before {
        top: -4px;
    }
}

//All Content
.kode_content {
    padding-right: 30px;
    padding-left: 30px;

    img {
        margin-bottom: 25px;
    }

    img[style*='left'] {
        margin-right: 25px;
    }

    img[style*='right'] {
        margin-left: 25px;
    }

    .kode_aside_cat {
        ul {
            margin-bottom: 0;

            li {
                &:before {
                    content: none;
                }

                a {
                    text-transform: none !important;
                }
            }
        }
    }

    ul {
        margin-bottom: 20px;
        padding-left: 15px;
        color: #999;

        li {
            position: relative;
            padding-bottom: 3px;
            padding-left: 20px;

            &:before {
                position: absolute;
                top: 3px;
                left: 0;
                margin-right: 10px;
                color: #bb0023;
                content: '\f10c';
                vertical-align: 3px;
                font-size: 10px;
                font-family: fontawesome;
            }
        }

        ul {
            margin-bottom: 0;

            li:before {
                position: absolute;
                top: 3px;
                left: 0;
                margin-right: 10px;
                color: #bb0023;
                content: '\f111';
                vertical-align: 3px;
                font-size: 10px;
                font-family: fontawesome;
            }
        }
    }

    .content-form fieldset {
        ul li:before {
            content: none;
        }

        input[type='submit'] {
            margin: 20px 0;
            border: 0;
        }
    }
}

.kode_aside_search input[type='text'] {
    padding: 15px 60px 15px 20px;
    width: 100%;
    height: 58px;
    border: 1px solid #e1e0e0;
    background-color: #fff;
}

.kode_aside_search .btn-search {
    position: absolute;
    top: 18px;
    right: 30px;
    background: none;
    color: #929292;
}

.kode_news_detail {
    padding-bottom: 8px;

    p {
        float: none;
    }

    ul {
        li {
            float: none;

            &:first-child {
                padding-left: 20px;
            }
        }
    }
}

.kode_hdg_1:first-child {
    margin-top: -4px;
}

.kode_hdg_1 {
    margin: 60px 0 50px;
}

form p {
    float: none;
    clear: both;
}

.alert {
    padding: 18px 60px 18px 20px;
}

.kode_event_timer_des img:first-of-type {
    display: none;
}

.kode_event_detail_timer img {
    transform: translateY(-35%);
}

.kode_content ul.event-details-list {
    margin: 0 0 20px 0;
    padding: 0;

    li {
        display: block;

        &:before {
            padding: 0;
            content: none;
        }
    }
}

.kf_touch_field {
    &.cb {
        width: 100%;

        label {
            display: inline;
        }
    }

    select {
        margin-bottom: 30px;
        padding: 8px 15px;
    }
}

.form-box-content {
    h2 {
        margin-top: 30px;
    }
}
//Contact Page

//Parks & Rec
.parks-list {
    .park {
        // margin: 15px 0;

        > div {
            display: inline-block;
        }

        .name {
            //   width: 300px;
            cursor: pointer;

            &:hover {
                color: #bb0023;
                text-decoration: underline;
            }
        }

        .map-link {
            //   width: 50%;
        }

        &[data-modal*='no modal'] .name:hover {
            color: #666;
            text-decoration: none;
            cursor: default;
        }
    }
}

.not-reservable,
.no-map {
    display: none;
}

//Bios
.bio-container,
.two-column,
.three-column {
    //   display: inline-block;
    //   margin-right: 50px;
    //   margin-bottom: 75px;
    //   width: calc(33% - 50px);
    //   vertical-align: top;

    .bio-link {
        // display: inline-block;
        // overflow: hidden;
        // height: 185px;
        display: none;
    }

    img {
        margin-bottom: 15px;
        max-height: 185px;
    }

    .col.mayor {
        width: 100%;

        .bio-link {
            display: inline-block;
            // float: left;
            // margin-right: 40px;
        }

        // p:first-of-type {
        //   font-size: 32px;
        //   line-height: 46px;

        //   strong {
        //     font-size: 46px;
        //   }
        // }
    }

    &.officials .col p:first-of-type {
        margin-bottom: 0;
    }
}

.radio-buttons li {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: 10px;
}

//Footer Styles
.kode_footer_bg {
    margin-top: 50px;
}

.kode_copyright {
    color: #919191;
}

.btn.btn-primary {
    border-color: #bb0023;
    background-color: #bb0023;
    color: white;

    &.btn-wide {
        display: block;
    }

    &.btn-large {
        padding: 12px 24px;
        font-size: 20px;
    }
}

// Search Results
.search-results-search {
    overflow: hidden;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 2px 1px rgba(black, 0.24);

    @media (min-width: 768px) {
        width: 50%;
    }

    input[type='search'] {
        float: left;
        padding: 0 5px;
        width: calc(100% - 72px);
        height: 32px;
        font-size: 16px;
        line-height: 32px;

        &::-webkit-input-placeholder {
            color: #999;
        }

        &::-moz-placeholder {
            color: #999;
        }

        &:-ms-input-placeholder {
            color: #999;
        }

        &:-moz-placeholder {
            color: #999;
        }
    }

    .search-submit {
        display: inline-block;
        float: right;
        padding: 6px 12px;
        width: 72px;
        height: 32px;
        border: 0;
        background-color: #293f9b;
        background-image: none;
        color: #1a1a1a;
        vertical-align: middle;
        text-align: center;
        white-space: nowrap;
        font-weight: 400;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.42857143;

        touch-action: manipulation;
        user-select: none;

        &:visited {
            background-color: #293f9b;
            color: #1a1a1a;
        }

        &:hover,
        &:focus {
            background-color: #bb0023;
            color: white;
        }
    }
}

form[action*='site-map'] {
    .kode_content ul.ul1> li {
        > .a1 {
            display: block;
            margin-top: 15px;
            margin-bottom: 5px;
            color: #000;
            font-weight: bold;
            font-size: 1.25em;
        }

        &:before {
            content: none;
        }
    }

    .ul1 a {
        color: #bb0023;

        &:hover,
        &:focus,
        &:visited {
            color: #bb0023;
        }
    }
}

.no-site {
    display: none;
}

tr.no-link {
    .has-site {
        display: none;
    }

    .no-site {
        display: table-cell;
    }
}

body.open-modal {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

a[data-toggle='no modal'] {
    color: #999;
    cursor: text;

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: #999;
    }
}

.lrs-modal-container {
    position: absolute;
    z-index: 100000;
    display: none;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    &.open {
        display: block;
    }
}

.lrs-modal {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: auto;
    padding: 15px 20px;
    max-width: 100%;
    width: 100vw;
    border: 1px solid #dfdfdf;
    background: #fff;
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 30px 40px;
        min-height: 30vh;
        width: 80vw;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
    }

    h2 {
        margin-bottom: 40px;
    }

    img {
        width: 100%;

        @media screen and (min-width: 768px) {
            float: left;
            margin-right: 20px;
            max-width: 70%;
        }
    }

    .btn.btn-primary.bottom {
        @media screen and (min-width: 768px) {
            position: absolute;
            right: 30px;
            bottom: 30px;
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    min-height: calc(100% - (0.5rem * 2));

    -webkit-box-align: center;
    align-items: center;
}

.modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    font-weight: 700;
    font-size: 34px;
}

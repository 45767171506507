@import url(https://fonts.googleapis.com/css?family=Sintony:400,700&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic&subset=latin,vietnamese,cyrillic-ext,latin-ext,cyrillic,greek,greek-ext);

* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
}

body {
    background-color: #fff;
    color: #666;
    font-weight: normal;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

/* Heading Typo */
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    margin: 0 0 0 0;
    color: #333;
    font-weight: 400;
    font-style: normal;
    font-family: 'Sintony', sans-serif;
    line-height: 1.6;
}

h1 {
    font-size: 62px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 30px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 18px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #3d3d3d;
}

p a {
    color: #bb0023;
}

.kode_footer_bg p a {
    color: #c8c8c8;
}

.kode_copyright> a .fa-angle-up:before {
    color: #c8c8c8;
}

/* Peragraph Typo */
p {
    color: #999;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 24px;
}

a {
    color: #000;
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

ul ul {
    margin: 0;
    padding: 0;
}

ol {
    float: none;
    padding-left: 15px;
    list-style: decimal;
}

ol ol {
    margin-left: 20px;
}

ol li {
    width: 100%;
    list-style: decimal;
}

figure {
    position: relative;
    float: left;
    overflow: hidden;
    width: 100%;
}

.clear {
    clear: both;
    font-size: 0;
    line-height: 0;
}

img {
    max-width: 100%;
}

strong {
    color: #3d3d3d;
}

iframe {
    float: left;
    max-width: 100%;
    border: none;
}
/* Form Input Typo */
select {
    display: inline-block;
    padding: 8px 12px;
    width: 100%;
    height: 40px;
    border: 1px solid #d2d2d2;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #3d3d3d;
    font-weight: 400;
}

button {
    border: none;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
    border: 1px solid #fff;
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

form p {
    position: relative;
    float: left;
    width: 100%;
}

form p span i {
    position: absolute;
    top: 13px;
    left: 16px;
    color: #474747;
}

table {
    max-width: 100%;
    width: 100%;
    background-color: transparent;
}

th {
    text-align: left;
}

table> thead> tr> th,
table> tbody> tr> th,
table> tfoot> tr> th,
table> thead> tr> td,
table> tbody> tr> td,
table> tfoot> tr> td {
    padding-left: 7px;
    vertical-align: top;
    line-height: 2.5;
}

table thead tr th {
    border-top: 1px solid #d2d2d2;
}

.table> caption+ thead> tr:first-child> th,
.table> colgroup+ thead> tr:first-child> th,
.table> thead:first-child> tr:first-child> th,
.table> caption+ thead> tr:first-child> td,
.table> colgroup+ thead> tr:first-child> td,
.table> thead:first-child> tr:first-child> td {
    border-top: 1px solid #d2d2d2;
}

table> thead> tr> th {
    border-bottom: 2px solid #d2d2d2;
    vertical-align: bottom;
}

table> caption+ thead> tr:first-child> th,
table> colgroup+ thead> tr:first-child> th,
table> thead:first-child> tr:first-child> th,
table> caption+ thead> tr:first-child> td,
table> colgroup+ thead> tr:first-child> td,
table> thead:first-child> tr:first-child> td {
    border-bottom: 0;
}

table> tbody+ tbody {
    border-top: 2px solid #d2d2d2;
}

.table> thead> tr> th,
.table> tbody> tr> th,
.table> tfoot> tr> th,
.table> thead> tr> td,
.table> tbody> tr> td,
.table> tfoot> tr> td {
    padding: 12px 16px;
}

p ins {
    color: #999;
}

dl dd {
    margin-left: 20px;
}

address {
    font-style: italic;
}

::-webkit-input-placeholder {
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
}

:-moz-placeholder {
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
}

::-moz-placeholder {
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
}

.placeholder2::-webkit-input-placeholder {
    color: #939393;
    text-transform: capitalize;
    opacity: 1;
}

.placeholder2:-moz-placeholder {
    color: #939393;
    text-transform: capitalize;
    opacity: 1;
}

.placeholder2::-moz-placeholder {
    color: #939393;
    text-transform: capitalize;
    opacity: 1;
}

.placeholder2:-ms-input-placeholder {
    color: #939393;
    text-transform: capitalize;
    opacity: 1;
}

.search::-webkit-input-placeholder {
    color: #888;
    text-transform: capitalize;
    opacity: 1;
}

.search:-moz-placeholder {
    color: #888;
    text-transform: capitalize;
    opacity: 1;
}

.search::-moz-placeholder {
    color: #888;
    text-transform: capitalize;
    opacity: 1;
}

.search:-ms-input-placeholder {
    color: #888;
    text-transform: capitalize;
    opacity: 1;
}

.comming_place::-webkit-input-placeholder {
    color: #27282d;
    text-transform: uppercase;
    opacity: 1;
}

.comming_place:-moz-placeholder {
    color: #27282d;
    text-transform: uppercase;
    opacity: 1;
}

.comming_place::-moz-placeholder {
    color: #27282d;
    text-transform: uppercase;
    opacity: 1;
}

.comming_place:-ms-input-placeholder {
    color: #27282d;
    text-transform: uppercase;
    opacity: 1;
}

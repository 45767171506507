.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 640px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .col,
  li.col {
    width: 100%;
  }

  li.col {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.two-column .col,
ul.two-column li.col {
  @media (min-width: 640px) {
    width: 50%;
    padding: 0 15px 15px 15px;
  }
}

.three-column .col,
ul.three-column li.col {
  @media (min-width: 640px) {
    width: 50%;
    padding: 0 15px 15px 15px;
  }

  @media (min-width: 768px) {
    width: 33.3%;
  }
}

.four-column .col,
ul.four-column li.col {
  @media (min-width: 640px) {
    width: 50%;
    padding: 0 15px 15px 15px;
  }

  @media (min-width: 768px) {
    width: 25%;
  }
}

.five-column .col,
ul.five-column li.col {
  @media (min-width: 640px) {
    width: 50%;
    padding: 0 15px 15px 15px;
  }

  @media (min-width: 768px) {
    width: 20%;
  }
}

.six-column .col,
ul.six-column li.col {
  @media (min-width: 640px) {
    width: 50%;
    padding: 0 15px 15px 15px;
  }

  @media (min-width: 768px) {
    width: 20%;
  }

  @media (min-width: 991px) {
    width: 16.66666666666667%;
  }
}
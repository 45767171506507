/*==================================================

Project:	Democracy - The political Theme
Version:	1.0
Last change:	05/11/15
Author :	KodeForest

/**
 * Table of Contents :
 *
* 1.0 - Kode Wrapper
* 2.0 - TOP STRAIP WRAP CSS START
* 3.0 - TLOGO & NAVIGATION WRAP CSS START
* 4.0 - UI Element Style
* 5.1 - 1st DropDown Menu
* 5.2 - 2nd DropDown Menu
* 6.0 - Favorite and Search Wrap Start
* 7.0 - BANNER WRAP CSS START
* 8.1 - BREAKING NEWS WRAP CSS START
* 8.2 - Breaking News 2
* 9.0 - PROGRAM LIST WRAP CSS START
* 10.0 - WELCOME TO DEMOCRACY WRAP CSS START
* 11.0 - WHAT WE DO WRAP CSS START
* 12.0 - HEADING WRAP CSS START
* 13.0 - Heading 2 Wrap
* 14.0 - NEWS & PRESS RELEASE WRAP CSS START
* 15.0 - LATEST WORK WRAP CSS START
* 16.0 - PURCHASE NOW WRAP CSS START
* 17.0 - OUR POLITICAL CAMPAIGN WRAP CSS START
* 18.0 - OUR POLITICIAN WRAP CSS START
* 19.0 - PROGRESS BAR WRAP CSS START
* 20.0 - COUNT UP WRAP CSS START
* 21.0 - LATEST BLOG POST WRAP CSS START
* 22.0 - VIDEO & AUDIO WRAP CSS START
* 23.0 - PURCHASE NOW 2 WRAP CSS START
* 24.0 - COMMING UP ELECTION WRAP CSS START
* 25.0 - TESTIMONIAL WRAP CSS START
* 26.0 - CONTACT WITH US WRAP CSS START
* 27.0 - BREAD CRUMB WRAP CSS START
* 28.0 - ABOUT US SERVICES WRAP CSS START
* 29.0 - SOCIAL COUNTER WRAP CSS START
* 30.0 - OUR ACHIEVEMENT WRAP CSS START
* 31.0 - WHERE WE ARE WRAP CSS START
* 32.0 - BECOME A VOLUNTEER WRAP CSS START
* 33.0 - OUR SKILLS WRAP CSS START
* 34.0 - WHY WE ARE WRAP CSS START
* 35.0 - SUBCRIBE WRAP CSS START
* 36.0 - PAGINATION WRAP CSS Start
* 37.0 - NEWS WRAP CSS START
* 38.0 - NEWS LIST WRAP CSS START
* 39.0 - EVENT WRAP CSS START
* 40.0 - OUR FOCUS WRAP CSS START
* 41.0 - HOW IT WORK WRAP CSS START
* 42.0 - ADVANCE SERVICES WRAP CSS START
* 43.0 - WHY CHOOSE US WRAP CSS START
* 44.0 - TEAM WRAP CSS START
* 45.0 - BACKGROUND WRAP CSS START
* 46.0 - EDUCATION WRAP CSS START
* 47.0 - LATEST WORK WRAP CSS START
* 48.0 - POLITICAL CAREER WRAP CSS START
* 49.0 - PERFORMANCE CHART WRAP CSS START
* 50.0 - YEARLY PERFORMANCE WRAP CSS START
* 51.0 - EVENT DETAIL WRAP CSS START
* 52.0 - NEWS DETAIL WRAP CSS START
* 53.0 - CONTACT US WRAP CSS START
* 54.0 - GET IN TOUCH WRAP CSS START
* 55.0 - 404 PAGE WRAP CSS START
* 56.0 - COMMING SOON PAGE WRAP CSS START
* 57.0 - TEAM LISTING WRAP CSS START
[Color codes]

Background:	#ffffff (white)
Content:	#999999 (light black)
Header h1:	#111111 (dark black)
Header h2:	#111111 (dark black)
Footer:		#000000 (dark black)

a (standard):	#999999 (light black)
a (visited):	#999999 (light black)
a (active):	#999999 (light black)

[Typography]

Body copy:		14px/ 'Lato', sans-serif;
Headers:		'Josefin Sans', sans-serif;
Input, textarea:	'Lato', sans-serif;
Sidebar heading:	'Lato', sans-serif;

-------------------------------------------------------------------*/
/*
	  ==============================================================
		   kode wrapper
	  ==============================================================
*/

.kode_wrapper,
.kode_content,
section {
    float: left;
    overflow: hidden;
    width: 100%;
}

header {
    float: left;
    width: 100%;
}

section {
    padding: 70px 0;
}

/*
	  ==============================================================
		   TOP STRAIP WRAP CSS START
	  ==============================================================
*/
header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    padding-bottom: 5px;
}

.kode_top_strip {
    width: 100%;
}

.kode_cnt_info {
    float: left;
}

.kode_cnt_info ul,
.kode_scl_icon ul {
    float: left;
    width: 100%;
}

.kode_cnt_info ul li {
    position: relative;
    display: inline-block;
    padding: 10px 16px;
    color: #fff;
}

.kode_cnt_info ul li i {
    margin-right: 5px;
}

.kode_cnt_info ul li:first-child {
    padding-left: 0;
}

.kode_scl_icon {
    float: right;
}

.kode_scl_icon ul li {
    position: relative;
    display: inline-block;
    transform: skewX(-30deg);
}

.kode_scl_icon ul li a {
    display: block;
    padding: 12px 12px 13px;
    color: #fff;
    transform: skewX(30deg);
}

/*
	  ==============================================================
		   TOP STRAIP WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   LOGO & NAVIGATION WRAP CSS START
	  ==============================================================
*/
.kode_navigation_outr_wrap {
    position: relative;
    float: left;
    width: 100%;
    background-color: #fff;
}

.kode_navigation_outr_wrap:before {
    position: absolute;
    top: -4px;
    left: 0;
    width: 37.5%;
    height: 2px;
    content: '';
}

.kode_navigation_outr_wrap:after {
    position: absolute;
    right: 0;
    bottom: -4px;
    width: 65%;
    height: 2px;
    background-color: #fff;
    content: '';
}

.kode_navigation_outr_wrap .container {
    position: relative;
    z-index: 9999999;
}

.kode_logo {
    position: relative;
    float: left;
    padding: 25px 20px;
}

.kode_logo:before {
    position: absolute;
    top: 0;
    right: 100%;
    width: 2000%;
    height: 100%;
    content: '';
}

.kode_logo:after {
    position: absolute;
    top: 0;
    right: -26px;
    width: 100px;
    height: 100%;
    content: '';
    transform: skew(-25deg);
}

.kode_logo img {
    position: relative;
    z-index: 10;
}

.kode_logo a {
    display: block;
}
/*UI Element Style*/
.kode_ui_element {
    float: right;
    padding-top: 18px;
}

.kode_menu {
    float: left;
}

.kode_menu ul {
    float: left;
    width: 100%;
}

.kode_menu ul li {
    position: relative;
    float: left;
}

.kode_menu ul li:before {
    position: absolute;
    right: 0;
    bottom: -22px;
    left: 0;
    margin: auto;
    width: 0;
    height: 2px;
    content: '';
}

.kode_menu ul li a {
    display: block;
    padding: 25px 15px;
    color: #27282d;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.kode_menu ul li.active:before,
.kode_menu ul li:hover:before,
.kode_menu ul li:focus:before {
    width: 70%;
}
/*1st DropDown Menu*/
.kode_menu ul ul {
    position: absolute;
    top: 50%;
    left: -50px;
    visibility: hidden;
    padding-top: 20px;
    width: 250px;
    border-bottom: 4px solid;
    background-color: #fff;
    opacity: 0;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
            transform: scale(0);
}

.kode_menu ul ul li {
    position: relative;
    float: left;
    width: 100%;
}

.kode_menu ul ul li:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 0;
    height: 100%;
    content: '';
}

.kode_menu ul ul> li:hover> a {
    color: #fff;
}

.kode_menu ul ul li:hover:after {
    width: 100%;
}

.kode_menu ul ul li a {
    padding: 15px 15px;
}

.kode_menu ul ul li:before {
    display: none;
    content: '';
}

.kode_menu ul> li:hover> ul {
    top: 100%;
    left: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
}
/*2nd DropDown Menu*/
.kode_menu ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
}

.kode_menu ul ul> li:hover> ul {
    top: 0;
    left: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
}
/*Favorite and Search Wrap Start*/
.search-wrapper-area {
    position: absolute;
    right: 0;
    display: none;
    padding: 20px;
    background: #fff;
}

.search-area {
    position: relative;
}

.search-area input[type='text'] {
    float: left;
    padding: 5px 15px;
    height: 40px;
    border: 1px solid #ccc;
}

.search-area input[type='submit'] {
    position: absolute;
    right: 0;
    float: left;
    padding: 10px;
    border: none;
    background: #ccc;
    color: #333;
}

.kode_favorite,
.kode_search {
    position: relative;
    float: left;
    margin-top: 14px;
    margin-left: 10px;
}

.dl-menuwrapper button,
.kode_favorite a i,
.kode_search a i {
    padding: 9px;
    border: 2px solid #9e9ea0;
    color: #27282d;
}

.dl-menuwrapper button:hover,
.kode_favorite:hover a i,
.kode_search:hover a i {
    color: #fff;
}

.dl-menuwrapper button:hover:after {
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}

.dl-menuwrapper {
    display: none;
    float: left;
    margin-top: 14px;
}

.dl-menuwrapper button {
    padding: 0;
    width: 40px;
    height: 36px;
    background: #fff;
    color: #333;
}

.dl-menuwrapper button:after {
    top: 8px;
    background: #333;
    box-shadow: 0 7px 0 #333, 0 14px 0 #333;
}

/*
	  ==============================================================
		   LOGO & NAVIGATION WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   BANNER WRAP CSS START
	  ==============================================================
*/
.kode_banner {
    position: relative;
    float: left;
    width: 100%;
}

.kode_banner ul.bxslider> li {
    position: relative;
    float: left;
}

.kode_banner ul.bxslider> li:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
}

.kode_banner ul li img {
    width: 100%;
    height: auto;
}

.kode_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -160px 0 0 -558px;
    width: 535px;
}

.kode_caption h6 {
    margin: 0 0 16px;
    padding-left: 10px;
    border-left: 4px solid;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 15px;
}

.kode_caption h2 {
    margin: 0 0 8px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}

.kode_caption h2 span {
    display: inline-block;
    font-weight: normal;
}

.kode_caption p {
    margin: 0 0 32px;
    color: #fff;
    word-break: break-all;
    font-size: 16px;
    line-height: 30px;
}

.kode_caption a.kode_link_1:hover {
    color: #fff;
}

.kode_caption a.kode_link_1:hover:before {
    background-color: #fff;
}

.kode_banner .bx-wrapper .bx-pager {
    padding: 0;
}

.kode_banner .bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    bottom: 150px;
    left: 50%;
    margin-left: -566px;
    width: 538px;
    text-align: left;
}

.kode_banner .bx-wrapper .bx-pager.bx-default-pager a {
    background-color: #fff;
}

.kode_banner .bx-wrapper .bx-pager.bx-default-pager a:hover,
.kode_banner .bx-wrapper .bx-pager.bx-default-pager a.active {
    background-color: #8e8e91;
}

/*
	  ==============================================================
		   BANNER WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   BREAKING NEWS WRAP CSS START
	  ==============================================================
*/
.kode_breaking_news {
    position: absolute;
    right: 0;
    bottom: 40px;
    left: 15px;
    float: left;
    margin: auto;
    width: 1140px;
    border: 2px solid;
    background-color: #fff;
}

.kode_breaking_hdg {
    position: relative;
    float: left;
    padding: 16px 20px 15px;
}

.kode_breaking_hdg:before {
    position: absolute;
    top: 19px;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 15px solid;
    border-left: 15px solid;
    content: '';
    transform: rotate(45deg);
}

.kode_breaking_news h6 {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 18px;
}

.kode_brekg_news_des {
    float: none;
    padding: 13px 50px 15px 200px;
    background-color: #fff;
}

.kode_brekg_news_des ul {
    float: left;
    width: 100%;
}

.kode_brekg_news_des ul li {
    display: inline-block;
    margin: 0;
    color: #000;
    line-height: 22px;
    line-height: 18px;
}

.kode_brekg_news_des .bx-pager.bx-default-pager,
.kode_breaking_news2 .kode_brekg_news_des .bx-pager.bx-default-pager {
    display: none;
}

.kode_brekg_news_des .bx-controls-direction {
    position: absolute;
    top: 18px;
    right: -30px;
    width: 60px;
}

.kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-prev,
.kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-prev,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next {
    position: absolute;
    top: -5px;
    right: 0;
}

.kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-prev:before,
.kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next:before,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-prev:before,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '\f104';
    text-indent: 0;
    font-size: 20px;
    font-family: fontawesome;
}

.kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next:before,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next:before {
    content: '\f105';
}

.kode_breaking_news .bxslider div.item {
    float: left;
}
/*Breaking News 2*/
.kode_breaking_news2 {
    position: relative;
    float: left;
    width: 100%;
    border-bottom: 2px solid;
}

.kode_breaking_news2:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 29%;
    height: 100%;
    background-color: #fff;
    content: '';
}

.kode_breaking_news2:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 71%;
    height: 100%;
    content: '';
}

.kode_breaking_news2 .container {
    position: relative;
    z-index: 99999;
}

.kode_breaking_news2 .kode_brekg_news_des {
    position: relative;
    background: none;
}

.kode_breaking_news2 .kode_breaking_hdg {
    background: none;
}

.kode_breaking_news2 .kode_breaking_hdg:before {
    position: absolute;
    top: 22px;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 15px solid #fff;
    border-left: 15px solid #fff;
    content: '';
    transform: rotate(45deg);
}

.kode_breaking_news2 .kode_breaking_hdg h6 {
    font-weight: bold;
}

.kode_breaking_news2 .kode_brekg_news_des p {
    padding-top: 5px;
    color: #fff;
    font-weight: bold;
}

.kode_breaking_news2 .kode_brekg_news_des .bx-controls-direction {
    position: absolute;
    top: 25px;
    right: -30px;
    width: 60px;
}

.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-prev:before,
.kode_breaking_news2 .kode_brekg_news_des .bx-wrapper .bx-controls-direction a.bx-next:before {
    color: #fff;
}

/*
	  ==============================================================
		   BREAKING NEWS WRAP CSS START
	  ==============================================================
*/


/*
	  ==============================================================
		   PROGRAM LIST WRAP CSS START
	  ==============================================================
*/
.kode_program_bg {
    float: left;
    padding: 15px 0 0;
    width: 100%;
}

.kode_program_list {
    position: relative;
    float: left;
    overflow: hidden;
    width: 100%;
}

.kode_program_list:before {
    position: absolute;
    top: -116px;
    left: -320px;
    z-index: 9;
    width: 160%;
    height: 100%;
    background-color: rgba(55, 45, 44, 0.65);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.65);
    content: '';
    transform: rotate(-30deg);
}

.kode_program_list:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    background-color: #15bfe9;
    content: '';
}

.kode_program_list figure img {
    width: 100%;
    height: auto;
}

.kode_program_des {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    padding: 30px 0 0 30px;
    width: 100%;
    height: 100%;
}

.kode_program_des h5 {
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 20px;
}

.kode_program_des p {
    margin: 0 0 15px;
    color: #fff;
    line-height: 16px;
}

.kode_program_des i {
    color: #fff;
    font-size: 100px;
}

.kode_program_hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    margin-top: -92px;
    padding: 0 40px;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_program_hover h5 {
    margin: 0 0 19px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 20px;
}

.kode_program_hover p {
    margin-bottom: 20px;
    color: #fff;
    word-break: break-all;
    line-height: 24px;
}

.kode_program_list:hover .kode_program_des {
    top: 50%;
    opacity: 0;
}

.kode_program_list:hover:before {
    top: -100%;
    left: -100%;
    opacity: 0;
}

.kode_program_list:hover .kode_program_hover {
    top: 50%;
    opacity: 1;
}

.kode_program_list:hover:after {
    width: 100%;
    height: 100%;
    opacity: 0.7;
}

.kode_program_list a:hover {
    color: #000;
}

figure img {
    -webkit-transition: opacity 0.35s;
            transition: opacity 0.35s;
}

.kode_program_list figure::before,
.kode_program_list figure::after {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
}

.kode_program_list figure::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
}

.kode_program_list figure::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
}

.kode_program_list:hover figure::before,
.kode_program_list:hover figure::after {
    z-index: 9999;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
/*
	  ==============================================================
		   PROGRAM LIST WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   WELCOME TO DEMOCRACY WRAP CSS START
	  ==============================================================
*/


ul.kode_tab_lnk {
    float: left;
    margin-bottom: 104px;
    width: 100%;
    text-align: center;
}

.kode_welcome_bg ul.kode_tab_lnk {
    margin-bottom: 85px;
}

ul.kode_tab_lnk li {
    position: relative;
    display: inline-block;
}

ul.kode_tab_lnk li:before,
ul.kode_tab_lnk li:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 1px;
    content: '';
    opacity: 0;
}

ul.kode_tab_lnk li:after {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 0;
    opacity: 0;
}

ul.kode_tab_lnk li a {
    display: block;
    padding: 10px 15px;
    color: #999;
    text-transform: uppercase;
    font-weight: bold;
}

ul.kode_tab_lnk li.active:before,
ul.kode_tab_lnk li:hover:before {
    width: 50px;
    opacity: 1;
}

ul.kode_tab_lnk li.active:after,
ul.kode_tab_lnk li:hover:after {
    width: 40px;
    opacity: 1;
}

/*Image Wrap Style*/
.kode_wel_outr_wrap {
    float: left;
    margin-bottom: 25px;
    width: 100%;
}

.kode_welcome {
    position: relative;
    float: left;
    width: 100%;
}

.kode_welcome:before {
    position: absolute;
    top: -35px;
    left: -58px;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: 9px solid #27282d;
    background-color: #fff;
    content: '';
}

.kode_welcome:after {
    position: absolute;
    top: -24px;
    left: -28px;
    z-index: -2;
    width: 100%;
    height: 110%;
    border: 3px solid #dfdfdf;
    content: '';
}

.kode_welcome figure img {
    width: 100%;
    height: auto;
}
/*Image Description Wrap Start*/
.kode_wel_demo_des {
    float: left;
    margin-top: 17px;
    width: 100%;
}

.kode_wel_demo_des h6 {
    margin-bottom: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
}

.kode_wel_demo_des h4 {
    position: relative;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 18px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 24px;
}

.kode_wel_demo_des h4:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 3px;
    background-color: #000;
    content: '';
}

.kode_wel_demo_des p {
    margin-bottom: 20px;
    color: #666;
    word-break: break-all;
    font-weight: 400;
    line-height: 29px;
}

ul.kode_tab_lnk.nav> li> a:focus,
ul.kode_tab_lnk.nav> li> a:hover {
    background: none;
}

.kode_wel_demo_des a.kode_link_3:hover {
    color: #fff;
}
/*About US Welcome*/
.kode_about_welcome {
    padding: 95px 0 85px;
}

.kode_about_welcome a.kode_link_3:hover i {
    color: #fff;
}
/*
	  ==============================================================
		   WELCOME TO DEMOCRACY WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   WHAT WE DO WRAP CSS START
	  ==============================================================
*/
.kode_wht_otr_wrap {
    position: relative;
    float: left;
    width: 100%;
    background-color: #202020;
}

.kode_wht_otr_wrap:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: url(extra-images/wht-we-do-01.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
}

.kode_wht_otr_wrap:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
}

.kode_wht_des {
    position: relative;
    z-index: 9999999;
    float: left;
    padding: 69px 40px 26px;
    width: 100%;
}

.kode_wht_des> h6 {
    margin: 0 0 12px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.kode_wht_des> h4 {
    position: relative;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 24px;
}

.kode_wht_des h4:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 3px;
    background-color: #fff;
    content: '';
}

.kode_wht_des ul {
    z-index: 9999999;
    float: left;
    width: 100%;
}

.kode_wht_des ul li {
    position: relative;
    z-index: 9999;
    float: left;
    margin: 0;
    padding: 4px 0 28px 5px;
}

.kode_wht_des ul li span {
    display: inline-block;
    float: left;
    width: 62px;
    height: 62px;
    border: 5px solid #b0b6bb;
    border-radius: 100%;
    background-color: #fff;
    text-align: center;
    line-height: 62px;
}

.kode_wht_des ul li span i {
    font-size: 25px;
}

.kode_wht_icon_des {
    float: none;
    padding: 0 0 0 90px;
    width: auto;
}

.kode_wht_icon_des h6 {
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_wht_icon_des p {
    padding: 0 30px 0 0;
    color: #fff;
}

.kode_wht_icon_des h6 a {
    color: #fff;
}

.kode_wht_des .bx-wrapper .bx-pager {
    padding: 0;
    width: 20px;
    text-align: right;
    font-size: 0;
}

.kode_wht_des .bx-controls.bx-has-pager.bx-has-controls-direction {
    position: relative;
    right: 0;
    z-index: 99999;
}

.kode_wht_des .bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    right: 0;
    bottom: 170px;
    text-align: center;
}

.kode_wht_des .bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    margin-bottom: 5px;
}

.kode_wht_des .bx-wrapper .bx-pager.bx-default-pager a {
    width: 3px;
    height: 40px;
    border-radius: 0;
}

.kode_wht_des .bx-wrapper .bx-pager.bx-default-pager a:hover,
.kode_wht_des .bx-wrapper .bx-pager.bx-default-pager a.active {
    width: 5px;
}

.kode_wht_des .bx-viewport {
    z-index: 9999;
}
/*
	  ==============================================================
		   WHAT WE DO WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   HEADING WRAP CSS START
	  ==============================================================
*/
.kode_hdg_1,
.kode_hdg_2,
.kode_hdg_3 {
    position: relative;
    float: left;
    margin: -4px 0 50px;
    width: 100%;
    text-align: center;
}

.kode_hdg_1 h6,
.kode_hdg_2 h6,
.kode_hdg_3 h6 {
    margin-bottom: 1px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
}

.kode_hdg_1 h4,
.kode_hdg_2 h4,
.kode_hdg_3 h4 {
    position: relative;
    padding-bottom: 6px;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_hdg_1 h4:before,
.kode_hdg_2 h4:before,
.kode_hdg_3 h4:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 75px;
    height: 3px;
    background-color: #27282d;
    content: '';
}

/*Heading 2 Wrap*/
.kode_hdg_2 h6,
.kode_hdg_3 h6 {
    color: #fff;
}

.kode_hdg_2 h4,
.kode_hdg_3 h4 {
    color: #fff;
}

.kode_hdg_2 h4:before {
    background-color: #fff;
}
/*
	  ==============================================================
		   HEADING WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   NEWS & PRESS RELEASE WRAP CSS START
	  ==============================================================
*/
.kode_services_news_bg {
    background-color: #f7f7f7;
}

.kode_services_news_bg .kode_press_new_lst,
.kode_services_news_bg .margin_bottom {
    margin: 0;
}

.kode_press_news {
    position: relative;
    float: left;
    width: 100%;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.24);
}

.kode_press_new_lst {
    float: left;
    margin: 0 0 0;
    margin-bottom: 50px;
    width: 100%;
}

.kode_press_news:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    content: '';
}

.kode_press_news figure {
    background-color: rgba(0, 0, 0, 1);
}

.kode_press_news figure img {
    width: 100%;
    height: auto;
}

.kode_press_news figure.kode_pres1_style:before {
    position: absolute;
    bottom: -110px;
    left: -10px;
    z-index: 9;
    width: 110%;
    height: 50%;
    background-color: #fff;
    content: '';
    transform: rotate(0deg);
}

.kode_press_news figure.kode_pres1_style:after {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 9;
    width: 0;
    height: 3px;
    content: '';
    transform: rotate(-7deg);
}

.kode_press_news figure figcaption {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_press_news figure figcaption a {
    display: block;
    margin: -45px 0 0;
    color: #fff;
    font-weight: 300;
    font-size: 45px;
    font-family: 'Open Sans', sans-serif;
}

.kode_news_date {
    position: relative;
    float: left;
    margin-bottom: 15px;
    padding-left: 10px;
    width: 100%;
}

.kode_news_date ul li {
    position: relative;
    float: left;
}

.kode_news_date ul li a {
    padding: 10px 10px 5px;
    color: #474747;
    text-transform: uppercase;
    font-size: 12px;
}

.kode_news_des {
    float: left;
    padding: 0 20px 30px 20px;
    width: 100%;
}

.kode_news_des h6> a {
    position: relative;
    display: block;
    float: left;
    margin-bottom: 9px;
    width: 100%;
    color: #474747;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
}

.kode_news_des h6 a:before {
    position: absolute;
    top: 0;
    left: -22px;
    width: 3px;
    height: 100%;
    background-color: #474747;
    content: '';
}

.kode_news_des p {
    margin-bottom: 10px;
    color: #999;
    word-break: break-all;
}

.kode_news_des a {
    display: inline-block;
    float: right;
    color: #27282d;
}

.kode_news_des a i {
    margin-left: 5px;
    color: #27282d;
    font-weight: normal;
}

.kode_press_news:hover:before {
    width: 100%;
}

.kode_press_news:hover figure.kode_pres1_style:after {
    bottom: 32px;
    width: 100%;
}

.kode_press_news:hover figure figcaption {
    top: 50%;
    opacity: 1;
}

.kode_press_news:hover figure img {
    opacity: 0.4;
}

.kode_press_news:hover figure.kode_pres1_style:before {
    transform: rotate(-7deg);
}
/*STYLE 2*/
.kode_press_news figure.kode_pres2_style:before {
    position: absolute;
    bottom: -112px;
    left: -10px;
    z-index: 9;
    width: 110%;
    height: 50%;
    background-color: #fff;
    content: '';
    transform: rotate(0deg);
}

.kode_press_news figure.kode_pres2_style:after {
    position: absolute;
    bottom: 54px;
    left: 0;
    z-index: 9;
    width: 0;
    height: 3px;
    content: '';
    transform: rotate(7deg);
}

.kode_press_news:hover figure.kode_pres2_style:after {
    bottom: 32px;
    width: 100%;
}

.kode_press_news:hover figure.kode_pres2_style:before {
    transform: rotate(7deg);
}
/*LINK STYLE*/
.kode_press_link {
    float: left;
    width: 100%;
    text-align: center;
    line-height: 12px;
}

.kode_press_link a {
    border: 2px solid #000;
    color: #474747;
}

.kode_press_link a.kode_link_2:before {
    position: absolute;
    background: #000;
    content: '';
}

.kode_press_link a.kode_link_2:hover {
    border-color: #bb0023;
    color: #fff;
}

.kode_press_link a.kode_link_2:hover:before {
    background-color: #bb0023;
}

/*
	  ==============================================================
		   NEWS & PRESS RELEASE WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   LATEST WORK WRAP CSS START
	  ==============================================================
*/
.kode_latest_wrk_bg {
    padding-bottom: 61px;
    border-top: 1px solid #e9e9e9;
}

.kode_latest_galry {
    position: relative;
    float: left;
    width: 100%;
}

.kode_latest_galry ul.kode_tab_lnk {
    margin: 0 0 40px;
}
/*Gallery List Wrap Start*/
.filterable_container {
    margin-right: -1.5%;
    margin-left: -1.5%;
}

.filterable_container .home_gallery {
    float: left;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    padding-right: 0;
    padding-left: 1.5%;
    width: 24.7%;
}

.kode_galry_item {
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 2.8%;
    width: 100%;
}

.kode_galry_item:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
}

.kode_galry_item figure:before {
    position: absolute;
    right: -471px;
    bottom: -48px;
    z-index: 9;
    width: 110%;
    height: 50%;
    box-shadow: 0 0 0 5px #fff;
    content: '';
    opacity: 0;
    transform: rotate(-36deg);
}

.kode_galry_item figure img {
    width: 100%;
    height: auto;
}

.kode_galry_item figure figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_galry_item figure figcaption a {
    display: inline-block;
    color: #fff;
    font-size: 45px;
}

.kode_galry_des {
    position: absolute;
    right: 0;
    bottom: -50px;
    z-index: 99999;
    padding: 0 25px 20px 0;
    width: 100%;
    text-align: right;
    opacity: 0;
}

.kode_galry_des p {
    margin-bottom: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_galry_des h6 {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_galry_item:hover:before {
    height: 100%;
}

.kode_galry_item:hover figure figcaption {
    bottom: 41%;
    opacity: 1;
}

.kode_galry_item:hover figure:before {
    right: -171px;
    opacity: 1;
}

.kode_galry_item:hover figure:after {
    width: 100%;
}

.kode_galry_item:hover .kode_galry_des {
    bottom: 0;
    opacity: 1;
}

/*
	  ==============================================================
		   LATEST WORK WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   PURCHASE NOW WRAP CSS START
	  ==============================================================
*/
.kode_purchase_bg {
    position: relative;
    background-image: url(images/purchase-now-bg.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
}

.kode_purchase_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.7;
}

.kode_purchase_bg:after {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 0;
    margin: auto;
    width: 99%;
    height: 93%;
    border: 1px solid;
    content: '';
}

.kode_purchase_bg .container {
    position: relative;
}

.kode_purchase {
    position: relative;
    z-index: 99999;
    float: left;
    width: 100%;
    text-align: center;
    line-height: 12px;
}

.kode_purchase h6 {
    margin: 0 0 21px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 18px;
}

.kode_purchase h4 {
    margin: 0 0 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 24px;
}

.kode_purchase a {
    z-index: 9999;
    font-size: 16px;
}

.kode_purchase a.kode_link_2:hover {
    color: #000;
}

/*
	  ==============================================================
		   PURCHASE NOW WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   OUR POLITICAL CAMPAIGN WRAP CSS START
	  ==============================================================
*/
.kode_politician_bg {
    background: #f9f9f9;
}

.kode_camp_outr_wrap {
    float: left;
    width: 100%;
}

.kode_camp_outr_wrap> li {
    float: left;
    width: 100%;
}

.kode_campagin_lst {
    position: relative;
    float: left;
    width: 100%;
}

.kode_campgn_lst1 {
    position: relative;
    float: right;
    width: 50%;
}

.kode_cam_date {
    position: absolute;
    top: 10px;
    left: -40px;
    float: left;
    padding-top: 12px;
    width: 80px;
    height: 80px;
    border: 5px solid #757575;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 5px rgba(131, 131, 131, 1), 0 0 0 10px rgba(201, 201, 201, 1);
    text-align: center;
}

.kode_cam_date:before {
    position: absolute;
    top: 38px;
    left: 105px;
    width: 40px;
    height: 2px;
    background-color: #838383;
    content: '';
}

.kode_cam_date:after {
    position: absolute;
    top: 31px;
    left: 143px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #838383;
    content: '';
}

.kode_cam_date h4,
.kode_cam_date h6 {
    color: #838383;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 26px;
}

.kode_lst1_des {
    float: none;
    padding: 0 0 0 160px;
    width: auto;
}

.kode_lst1_des h6 {
    margin: 0 0 4px;
    color: #343434;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
}

.kode_lst1_des p {
    word-break: break-all;
    line-height: 28px;
}

.kode_lst1_des ul {
    float: left;
    margin-bottom: 6px;
    width: 100%;
}

.kode_lst1_des> ul> li {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    color: #838383;
    font-weight: bold;
}

.kode_lst1_des ul li i {
    margin-right: 5px;
}

.kode_lst1_des ul li:first-child {
    padding-left: 0;
}
/*List 2 Style*/
.kode_campgn_lst2 {
    position: relative;
    float: left;
    width: 50%;
}

.kode_campgn_lst2:after {
    position: absolute;
    top: 120px;
    bottom: 20px;
    left: 100%;
    width: 2px;
    background-color: #d8d8d8;
    content: '';
}

.kode_campgn_lst1:after {
    position: absolute;
    top: 120px;
    right: 100%;
    bottom: 20px;
    width: 2px;
    background-color: #d8d8d8;
    content: '';
}

.kode_campgn_lst2 .kode_cam_date {
    right: -40px;
    left: auto;
}

.kode_campgn_lst2 .kode_cam_date:before {
    right: 110px;
    left: auto;
}

.kode_campgn_lst2 .kode_cam_date::after {
    right: 150px;
    left: auto;
}

.kode_campgn_lst2 .kode_lst1_des {
    float: none;
    padding: 0 160px 0 0;
    text-align: right;
}
/*Hover Style*/
.kode_campgn_lst1:hover .kode_cam_date h4,
.kode_campgn_lst1:hover .kode_cam_date h6,
.kode_campgn_lst2:hover .kode_cam_date h4,
.kode_campgn_lst2:hover .kode_cam_date h6 {
    color: #fff;
}

.kode_campgn_lst1:hover .kode_cam_date,
.kode_campgn_lst2:hover .kode_cam_date {
    border: 5px solid #fff;
}

/*
	  ==============================================================
		   OUR POLITICAL CAMPAIGN WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   OUR POLITICIAN WRAP CSS START
	  ==============================================================
*/


.kode_meet_bg {
    padding-top: 58px;
}

.kode_politician {
    position: relative;
    float: left;
    padding: 0 0 30px;
    width: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.18);
}

.kode_politician figure {
    margin-bottom: 15px;
    border-bottom: 4px solid #434343;
}

.kode_politician figure img {
    width: 100%;
    height: auto;
}

.kode_politician figure:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(51, 51, 51, 0.8);
    content: '';
}

.kode_poli_img_des {
    position: absolute;
    top: 0;
    z-index: 99999;
    margin: -100px 0 0;
    padding: 0 25px;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_poli_img_des h6 a {
    display: inline-block;
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_poli_img_des span {
    display: block;
    margin-bottom: 20px;
    color: #ccc;
}

.kode_poli_img_des p {
    color: #ccc;
    word-break: break-all;
}

.kode_politician_des {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
}

.kode_politician_des:before {
    position: absolute;
    top: -15px;
    right: 0;
    left: 0;
    visibility: hidden;
    margin: auto;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
}

.kode_politician_des h6 {
    color: #27282d;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_politician_des p {
    color: #999;
    text-transform: capitalize;
}

.kode_politician_des ul {
    float: left;
    width: 100%;
}

.kode_politician_des ul li {
    position: relative;
    display: inline-block;
}

.kode_politician_des ul li a {
    display: block;
    padding: 0 15px;
    color: #777;
    font-size: 20px;
}

.kode_politician:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.18);
}

.kode_politician:hover figure:before {
    height: 100%;
}

.kode_politician:hover .kode_poli_img_des {
    top: 50%;
    opacity: 1;
}

.kode_politician:hover figure {
    border-bottom: 4px solid;
}

.kode_politician:hover .kode_politician_des:before {
    visibility: visible;
    opacity: 1;
}

/*
	  ==============================================================
		   OUR POLITICIAN WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   PROGRESS BAR WRAP CSS START
	  ==============================================================
*/
.kode_progress_bg {
    background: #f9f9f9;
}

.kode_progress {
    position: relative;
    float: left;
    margin-bottom: 30px;
    width: 100%;
}

.kode_progress h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.kode_progress .progress {
    margin: 0;
    height: 4px;
    border-radius: 0;
    background-color: #e3e5e8;
}

/*
	  ==============================================================
		   PROGRESS BAR WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   COUNT UP WRAP CSS START
	  ==============================================================
*/
.kode_countup_bg {
    position: relative;
    background-image: url(images/count-up-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_countup_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.9;
}

.kode_countup_bg .container {
    position: relative;
}

.kode_countup {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
}

.kode_countup i {
    margin-bottom: 10px;
    color: #fff;
    font-size: 50px;
}

.kode_countup h3 {
    position: relative;
    margin-bottom: 22px;
    padding-bottom: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_countup h3:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 55px;
    height: 2px;
    background-color: #fff;
    content: '';
}

.kode_countup p {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 14px;
}

/*
	  ==============================================================
		   COUNT UP WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   LATEST BLOG POST WRAP CSS START
	  ==============================================================
*/


.kode_latest_outer_wrap .kode_hdg_1 h6 {
    margin-bottom: 1px;
}

.kode_latest_outer_wrap .kode_hdg_1 h4 {
    padding-bottom: 7px;
}

.kode_latst_post_lst .bx-wrapper {
    float: left;
}

.kode_latest_blog {
    position: relative;
    float: left;
    width: 100%;
}

.kode_latest_blog:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
}

.kode_latest_blog figure img {
    width: 100%;
    height: auto;
}

.kode_latest_blog> h6 {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99999;
    padding: 8px 15px;
    background-color: #f00;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 14px;
}

.kode_latest_blog_des {
    position: absolute;
    bottom: 15px;
    z-index: 99999;
    padding: 0 80px 0 25px;
}

.kode_latest_blog_des h6 a {
    display: inline-block;
    margin-bottom: 8px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
}

.kode_latest_blog_des ul {
    float: left;
    width: 100%;
}

.kode_latest_blog_des ul li {
    position: relative;
    float: left;
    border-left: 2px solid #fff;
}

.kode_latest_blog_des ul li a {
    display: block;
    padding: 0 12px;
    color: #fff;
    line-height: 18px;
}

.kode_latest_blog_des ul li a i {
    margin-right: 5px;
}

.kode_latest_blog_des ul li:first-child {
    padding-left: 0;
    border-left: 0;
}

.kode_latst_post_lst .bx-wrapper .bx-pager {
    display: none;
}

.kode_latst_post_lst .bx-wrapper .bx-controls-direction a {
    border: 3px solid #fff;
    border-radius: 100%;
    background-color: #1eade5;
    text-align: center;
    text-indent: 0;
}

.kode_latst_post_lst .bx-wrapper .bx-controls-direction a.bx-prev {
    left: -15px;
}

.kode_latst_post_lst .bx-wrapper .bx-controls-direction a.bx-next {
    right: -15px;
}

.kode_latst_post_lst .bx-wrapper .bx-controls-direction a.bx-prev:before {
    position: absolute;
    top: -2px;
    left: 9px;
    color: #fff;
    content: '\f104';
    font-weight: bold;
    font-size: 20px;
    font-family: fontawesome;
}

.kode_latst_post_lst .bx-wrapper .bx-controls-direction a.bx-next:before {
    position: absolute;
    top: -2px;
    left: 9px;
    color: #fff;
    content: '\f105';
    font-weight: bold;
    font-size: 20px;
    font-family: fontawesome;
}
/*Blog Style 2*/
.kode_blog_style2 {
    margin-bottom: 20px;
}

/*
	  ==============================================================
		   LATEST BLOG POST WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   VIDEO & AUDIO WRAP CSS START
	  ==============================================================
*/
.kode_video_bg {
    float: left;
    padding: 70px 0;
    width: 100%;
    background-color: #262927;
}

.kode_audio_wrap {
    position: relative;
    float: left;
    width: 100%;
}

.kode_audio_wrap figure:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
}

.kode_audio_wrap figure img {
    width: 100%;
    height: auto;
}

.kode_audio_wrap figure figcaption {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_audio_wrap figure figcaption a {
    display: inline-block;
    margin-top: -30px;
    padding: 10px 20px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 30px;
}

.kode_audio_wrap figure:hover figcaption {
    opacity: 1;
}

.kode_video_list .owl-controls .owl-pagination,
.kode_video_list .owl-controls .owl-buttons {
    display: none;
}
/*Hover */
.kode_audio_wrap figure:hover:before {
    width: 100%;
    height: 100%;
}

/*
	  ==============================================================
		   VIDEO & AUDIO WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   PURCHASE NOW 2 WRAP CSS START
	  ==============================================================
*/
.kode_purchase_now2 {
    position: relative;
    float: left;
    width: 100%;
}

.kode_purchase_now2 h4 {
    float: left;
    padding-top: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 24px;
}

.kode_purchase_now2 a {
    z-index: 9999;
    float: right;
    text-transform: uppercase;
    font-size: 16px;
}

.kode_purchase_now2 a.kode_link_2:hover {
    color: #000;
}

/*
	  ==============================================================
		   COMMING UP ELECTION WRAP CSS START
	  ==============================================================
*/
.kode_counter_bg {
    border-bottom: 4px solid #f9f9f9;
}

.kode_coming_election {
    position: relative;
    float: left;
    padding: 90px 0;
    width: 100%;
    background-image: url(extra-images/upcomming-election-01.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_coming_election:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
}

.kode_coming_election:after {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 9999;
    margin: auto;
    width: 98%;
    height: 92%;
    border: 1px solid #a9a7aa;
    content: '';
}
/*Time Counter Style Start*/
.kode_counter_caption {
    position: relative;
    z-index: 999999;
    width: 100%;
    text-align: center;
}

.kode_counter_caption h5 {
    margin: 0 0 40px;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
}

ul.countdown {
    position: relative;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
}

ul.countdown:before {
    position: absolute;
    top: 0;
    left: 12px;
    width: 29%;
    height: 69.9%;
    border-top: 1px solid #a9a7aa;
    border-bottom: 1px solid #a9a7aa;
    content: '';
}

ul.countdown:after {
    position: absolute;
    top: 0;
    right: 12px;
    width: 29%;
    height: 69.9%;
    border-top: 1px solid #a9a7aa;
    border-bottom: 1px solid #a9a7aa;
    content: '';
}

ul.countdown li {
    position: relative;
    display: inline-block;
    margin-left: 30px;
}

.kode_banner_counter ul.countdown li:before {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    height: 50px;
    border-right: 1px solid #fff;
    content: '';
}

ul.countdown li span {
    display: inline-block;
    margin: 0 0 10px;
    padding: 0;
    width: 100px;
    height: 100px;
    border: 1px solid #ababac;
    background-color: rgba(171, 171, 171, 0.3);
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    line-height: normal;
    line-height: 100px;
}

ul.countdown li p {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
}

.days {
    padding: 0 10px;
    color: #fff;
}
/*
	  ==============================================================
		   COMMING UP ELECTION WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   TESTIMONIAL WRAP CSS START
	  ==============================================================
*/
.kode_testimonial_bg {
    background-image: url(images/testiminial-bg.png);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
}

.kode_testimonial_wrap .item {
    margin-left: 30px;
}

.kode_testi_wrap {
    position: relative;
    float: left;
    padding: 30px 0;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-bottom: 2px solid #dbd9d9;
    border-left: 1px solid #f2f2f2;
    background-color: #f9f9f9;
    text-align: center;
}

.kode_testi_wrap figure {
    margin-bottom: 20px;
}

.kode_testi_wrap figure img {
    border: 9px solid #dcd9d9;
    border-radius: 100%;
}

.kode_testi_wrap p {
    margin-bottom: 20px;
    padding: 0 30px;
    word-break: break-all;
    line-height: 26px;
}

.kode_testi_wrap a {
    display: block;
    color: #333;
}

.kode_testimonial_wrap .owl-pagination {
    display: none;
}

.kode_testimonial_wrap .owl-wrapper-outer {
    float: left;
    margin-bottom: 50px;
}

.kode_testimonial_wrap.owl-theme .owl-controls .owl-buttons div {
    position: relative;
    margin: 0;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border: 2px solid #dcd9d9;
    border-radius: 0;
    background: none;
    font-size: 0;
}

.kode_testimonial_wrap.owl-theme .owl-controls .owl-buttons div.owl-prev:before,
.kode_testimonial_wrap.owl-theme .owl-controls .owl-buttons div.owl-next:before {
    position: absolute;
    top: 0;
    left: 2px;
    width: 20px;
    height: 20px;
    color: #838383;
    content: '\f104';
    font-size: 20px;
    font-family: fontawesome;
    line-height: 25px;
}

.kode_testimonial_wrap.owl-theme .owl-controls .owl-buttons div.owl-next:before {
    content: '\f105';
}
/*
	  ==============================================================
		   TESTIMONIAL WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   CONTACT WITH US WRAP CSS START
	  ==============================================================
*/
.kode_contact_bg {
    position: relative;
    background-image: url(images/contact-us-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_contact_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
}

.kode_contact_bg .container {
    position: relative;
    z-index: 99999;
}
/*Contact Us Field Wrap Style*/
.kode_contact_wrap,
.kode_contact_field {
    float: left;
    width: 100%;
}

.kode_contact_wrap h5 {
    margin-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 26px;
    line-height: 20px;
}

.kode_contact_field {
    margin-bottom: 50px;
    text-align: center;
}

.kode_contact_field input[type='text'] {
    padding: 8px 0;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom: 1px solid #fff;
    background: none;
    color: #fff;
}

.kode_contact_field textarea {
    padding: 20px 0;
    width: 100%;
    height: 120px;
    border: 0;
    border-bottom: 1px solid #fff;
    background: none;
    color: #fff;
    resize: none;
}

.kode_contact_field input[type='submit'],
.kode_contact_field button {
    padding: 20px 25px;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Sintony', sans-serif;
    line-height: 16px;
}

.kode_contact_field input[type='submit']:hover,
.kode_contact_field button:hover {
    background-color: #000;
}
/*Contact Info Wrap Start*/
.kode_contact_info {
    float: left;
    margin-bottom: 35px;
    padding: 0 10px 0 50px;
    width: 100%;
}

.kode_contact_info h5 {
    margin-bottom: 40px;
}

.kode_contact_info i {
    float: left;
    width: 30px;
    color: #fff;
    font-size: 30px;
    line-height: 22px;
}

.kode_contact_des {
    float: none;
    padding: 0 0 0 50px;
}

.kode_contact_des span {
    display: block;
    color: #fff;
    font-size: 16px;
}

.kode_contact_des span a {
    color: #fff;
}
/*
	  ==============================================================
		   CONTACT WITH US WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   BREAD CRUMB WRAP CSS START
	  ==============================================================
*/
.kode_about_bg {
    position: relative;
    float: left;
    min-height: 350px;
    width: 100%;
    border-bottom: 3px solid;
    background-image: url(images/about-us-01.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_about_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
}

.kode_about_bg .container {
    position: relative;
}

.kode_aboutus_wrap {
    position: relative;
    float: left;
    padding: 209px 0 0;
    width: 100%;
    text-align: center;
}

.kode_aboutus_wrap h4 {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_bread_crumb {
    float: left;
    width: 100%;
}

.kode_bread_crumb ul li {
    position: relative;
    display: inline-block;
}

.kode_bread_crumb ul li:before {
    position: absolute;
    top: 0;
    left: -4px;
    color: #fff;
    content: '\f101';
    font-weight: bold;
    font-family: fontawesome;
}

.kode_bread_crumb ul li:first-child:before {
    font-size: 0;
}

.kode_bread_crumb ul li a {
    display: block;
    padding: 0 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}
/*
	  ==============================================================
		   BREAD CRUMB WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   ABOUT US SERVICES WRAP CSS START
	  ==============================================================
*/
.kode_services_abt_bg {
    background: #f9f9f9;
}

.kode_about_services {
    position: relative;
    float: left;
    padding: 0 20px 20px;
    width: 100%;
    text-align: center;
}

.kode_about_services i {
    display: block;
    margin: 0 0 16px;
    color: #3d3d3d;
    font-size: 60px;
}

.kode_about_services h5 a {
    position: relative;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 16px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 24px;
}

.kode_about_services h5 a:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 50px;
    height: 1px;
    background-color: #333;
    content: '';
}

.kode_about_services p {
    word-break: break-all;
    font-weight: 600;
    line-height: 26px;
}

/*
	  ==============================================================
		   ABOUT US SERVICES WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   SOCIAL COUNTER WRAP CSS START
	  ==============================================================
*/


.kode_social_item_list {
    position: relative;
    float: left;
    width: 100%;
}

.kode_social_item {
    position: relative;
    float: left;
    width: 100%;
    border-left: 1px solid #fff;
    text-align: center;
}

.kode_social_item i {
    float: left;
    padding-top: 15px;
    width: 100px;
    color: #fff;
    font-size: 50px;
}

.kode_social_des {
    float: none;
    padding: 0 0 0 60px;
    width: auto;
    text-align: left;
}

.kode_social_des h4 {
    display: inline-block;
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
}

.kode_social_des span {
    display: inline-block;
    color: #fff;
    font-weight: bold;
    font-size: 36px;
}

.kode_social_des p {
    margin: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
}

.kode_social_item_list .col-md-3:first-child .kode_social_item {
    border-left: 0;
}
/*
	  ==============================================================
		   SOCIAL COUNTER WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   OUR ACHIEVEMENT WRAP CSS START
	  ==============================================================
*/


.kode_achment_wrap {
    position: relative;
    float: left;
    width: 100%;
}

.kode_achment_wrap figure:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
}

.kode_achment_wrap figure img {
    width: 100%;
    height: auto;
}

.kode_achment_wrap figcaption {
    position: relative;
    position: absolute;
    top: 20px;
    left: 20px;
}

.kode_achment_wrap figcaption h6 {
    display: inline-block;
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

.kode_achment_des {
    float: left;
    padding: 32px 25px;
    width: 100%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
}

.kode_achevemnt_des_2 {
    float: left;
    padding: 20px 25px;
    width: 100%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
}

.kode_achment_des h5 {
    line-height: 20px;
}

.kode_achevemnt_des_2 h5 {
    padding: 5px 0 10px;
    line-height: 20px;
}

.kode_achment_des h5 a,
.kode_achevemnt_des_2 h5 a {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
}

.margin_bottom {
    margin-bottom: 40px;
}

/*
	  ==============================================================
		   OUR ACHIEVEMENT WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   WHERE WE ARE WRAP CSS START
	  ==============================================================
*/
.kode_where_bg {
    background: #f9f9f9;
}

.kode_where_we {
    position: relative;
    float: left;
    width: 100%;
}

.kode_where_we ul {
    position: relative;
    float: left;
    width: 100%;
}

.kode_where_we ul li {
    position: relative;
    display: inline-block;
    margin-bottom: 50px;
    padding: 0 90px 0 0;
    width: 48%;
}

.kode_where_we ul:before {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 50%;
    width: 2px;
    content: '';
}

.kode_year_list {
    position: relative;
    display: inline-block;
    width: 100%;
}

.kode_year_list figure {
    z-index: 9999;
    overflow: visible;
    margin-bottom: 40px;
    background-color: #000;
}

.kode_year_list figure img {
    width: 100%;
    height: auto;
}

.kode_year_list a:before {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    margin-top: -25px;
    width: 100%;
    color: #fff;
    content: '\f067';
    text-align: center;
    font-size: 40px;
    font-family: FontAwesome;
    transform: scale(0);
}

.kode_where_des h5 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
}

.kode_where_des p {
    word-break: break-all;
}

.kode_where_year {
    position: absolute;
    top: 120px;
    right: -160px;
    float: left;
    width: 90px;
    height: 90px;
    border: 5px solid #757575;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 5px rgba(131, 131, 131, 1), 0 0 0 10px rgba(201, 201, 201, 1);
    text-align: center;
}

.kode_where_year h6 {
    color: #838383;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 80px;
}
/*Second Li Style*/
.kode_where_we ul li:nth-child(even) {
    float: right;
    padding: 0 0 0 90px;
    width: 48%;
}

.kode_where_we ul li:nth-child(even) .kode_where_year {
    right: auto;
    left: -160px;
}

.kode_where_we ul li:nth-child(even) .kode_year_list figure {
    margin-bottom: 0;
}

.kode_where_we ul li:nth-child(even) .kode_where_des {
    margin-bottom: 40px;
}

.kode_where_we ul li:hover .kode_where_year {
    border: 5px solid #fff;
}

.kode_where_we ul li:hover .kode_where_year h6 {
    color: #fff;
}
/*Hover Style*/
.kode_where_we ul li:hover figure img {
    opacity: 0.3;
}

.kode_where_we ul li:hover figure a:before {
    transform: scale(1);
}

/*
	  ==============================================================
		   WHERE WE ARE WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   BECOME A VOLUNTEER WRAP CSS START
	  ==============================================================
*/
.kode_volunteer_bg {
    position: relative;
    background-image: url(images/volunter-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_volunteer_bg:before,
.kode_skill_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
}

.kode_volunteer_bg:container {
    position: relative;
    z-index: 99999;
}

.kode_volunteer {
    position: relative;
    margin: auto;
    width: 610px;
    text-align: center;
}

.kode_volunteer p {
    float: left;
    margin-bottom: 43px;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
}

.kode_volunteer_btn {
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 610px;
}

.kode_volunteer_btn a {
    position: relative;
    z-index: 99999;
    display: inline-block;
    float: left;
    padding: 10px 15px 10px 20px;
    width: 50%;
}

.kode_volunteer_btn a.donate:before {
    position: absolute;
    top: 0;
    right: -4px;
    left: -200px;
    z-index: -1;
    height: 100%;
    content: '';
    -webkit-transform: skewX(28deg);
       -moz-transform: skewX(28deg);
            transform: skewX(28deg);
}

.kode_volunteer_btn a.donate i {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 5px rgba(247, 165, 149, 0.7);
    vertical-align: middle;
    text-align: center;
    font-size: 28px;
    line-height: 48px;
}

.kode_volunteer_btn a.donate span {
    display: inline-block;
    padding-top: 1px;
    padding-left: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
}

.kode_volunteer_btn a.kf_volunteer:after {
    position: absolute;
    top: 0;
    right: -2000px;
    left: 15px;
    z-index: -1;
    height: 100%;
    background-color: #2196f3;
    content: '';
    transform: skewX(28deg);
}

.kode_volunteer_btn a.kf_volunteer i {
    display: inline-block;
    float: right;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 5px rgba(144, 203, 249, 0.7);
    color: #2196f3;
    vertical-align: middle;
    text-align: center;
    font-size: 28px;
    line-height: 48px;
}

.kode_volunteer_btn a.kf_volunteer span {
    display: inline-block;
    float: right;
    padding-top: 16px;
    padding-right: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
}

.kode_volunteer_btn a:hover {
    opacity: 0.7;
}
/*
	  ==============================================================
		   BECOME A VOLUNTEER WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   OUR SKILLS WRAP CSS START
	  ==============================================================
*/
.kode_skill_bg {
    position: relative;
    background-image: url(images/our-skill-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kode_volunteer_bg:container {
    position: relative;
}

.kode-skills {
    float: left;
    width: 100%;
    text-align: center;
}

.kode-skills ul {
    float: left;
    margin: 0 0 30px 0;
    width: 100%;
}

.kode-chart {
    position: relative;
    display: inline-block;
    margin: 20px 40px;
    padding-top: 22px;
    width: 185px;
    height: 195px;
    vertical-align: top;
    text-align: center;
}

.kode-chart span {
    display: inline-block;
    margin: 16% 0;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: #fff;
    color: #000;
    text-align: center;
    font-weight: normal;
    font-weight: bold;
    font-size: 18;
    font-size: 20px;
    line-height: 82px;
}

.kode-chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.kode-chart> h5 {
    position: absolute;
    bottom: -50px;
    left: 0;
    margin-bottom: 15px;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
}

/*
	  ==============================================================
		   OUR SKILLS WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   WHY WE ARE WRAP CSS START
	  ==============================================================
*/


.kode_goal_wrap {
    float: left;
    margin-bottom: 15px;
    width: 100%;
}

.kode_goal_wrap .accordion {
    padding: 15px 30px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.27);
    cursor: pointer;
}

.kode_goal_wrap .accordion {
    background-color: #f5f5f5;
}

.kode_goal_wrap .accordion-close h6,
.kode_goal_wrap .accordion-open h6 {
    color: #111;
    text-transform: capitalize;
    font-size: 18px;
}

.kode_goal_wrap .accordion-close h6:after {
    float: right;
    content: '\f105';
    font-size: 20px;
    font-family: fontawesome;
}

.goal_des {
    padding: 20px 30px;
}

.goal_des ul li {
    color: #666;
    line-height: 30px;
}

.goal_des ul li:before {
    margin-right: 10px;
    content: '\f10c';
    vertical-align: middle;
    font-size: 10px;
    font-family: fontawesome;
}

.kode_goal_wrap .accordion-open h6 {
    color: #fff;
}

.kode_goal_wrap .accordion-open h6:after {
    float: right;
    content: '\f107';
    font-size: 20px;
    font-family: fontawesome;
}
/*Planning Wrap Style*/
.kode_planning_wrap,
.kode_planning_detail {
    float: left;
    width: 100%;
}

.kode_planning_wrap .nav-tabs {
    border: 0;
}

.kode_planning_wrap .nav-tabs li a {
    margin: 0;
    border-radius: 0;
}

.kode_planning_wrap ul li {
    float: left;
    margin: 0 0.4%;
    width: 32.333%;
}

.kode_planning_wrap ul li a {
    display: block;
    padding: 10px 0;
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.27);
    color: #111;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
}

.kode_planning_wrap ul li.active a,
.kode_planning_wrap ul li:hover a {
    color: #fff;
}

.kode_planning_wrap .nav-tabs li.active a,
.kode_planning_wrap .nav-tabs li.active a:focus,
.kode_planning_wrap .nav-tabs li.active a:hover {
    color: #fff;
}

.kode_planning_detail {
    padding-top: 20px;
}

.planning_img {
    float: left;
    width: 30%;
}

.planning_img img {
    width: 100%;
    height: auto;
}

.planning_des {
    float: none;
    padding: 0 0 0 180px;
}

.planning_des p {
    color: #666;
    word-break: break-all;
    line-height: 22px;
}

p.planning_1 {
    padding-top: 10px;
    padding-bottom: 20px;
}
/*
	  ==============================================================
		   WHY WE ARE WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   SUBCRIBE WRAP CSS START
	  ==============================================================
*/
.kode_subcribe_des {
    position: relative;
    float: left;
    width: 100%;
}

.kode_subcribe_des h5 {
    float: left;
    padding-top: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_subcribe_des form {
    position: relative;
    float: right;
    width: 485px;
}

.kode_subcribe_des input[type='text'] {
    float: left;
    padding: 10px 100px 10px 30px;
    width: 100%;
    height: 52px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    font-weight: bold;
}

.kode_subcribe_des button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 40px;
    height: 51px;
    border: 3px solid #fff;
    background: none;
    line-height: none;
}

.kode_subcribe_des button i {
    color: #fff;
    font-size: 16px;
}
/*
	  ==============================================================
		   SUBCRIBE WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   PAGINATION WRAP CSS Start
	  ==============================================================
*/
.kode_pagination {
    float: left;
    margin-bottom: 12px;
    width: 100%;
    text-align: center;
}

.kode_pagination .pagination {
    margin: 0;
    border: 0;
    border-radius: 0;
}

.kode_pagination .pagination li {
    display: inline-block;
    margin-right: 3px;
}

.kode_pagination .pagination li a {
    margin: 0;
    padding: 6px 14px;
    border: 0;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    color: #666;
    font-size: 16px;
}

.kode_pagination .pagination li a span {
    padding: 4px 9px;
    border: 1px solid #cfcfcf;
}

.kode_pagination .pagination li a:focus,
.kode_pagination .pagination li a:hover {
    background: none;
}

.kode_pagination .pagination li:hover a,
.kode_pagination .pagination li:focus a {
    color: #fff;
}

.kode_pagination .bx-wrapper .bx-pager {
    display: none;
}
/*Second Pagination*/
.kode_pagination2 {
    margin-top: 20px;
    margin-bottom: 38px;
}

.kode_pagination ul.pagination {
    float: left;
    width: 100%;
}

.kode_pagination .pagination li a b {
    padding-top: 5px;
    border-top: 2px solid #000;
    color: #000;
}


/*
	  ==============================================================
		   PAGINATION WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   NEWS WRAP CSS START
	  ==============================================================
*/
.kode_news_wrap {
    margin-bottom: 30px;
    border: 1px solid #d9d9d9;
}

.kode_news_wrap,
.kode_news_wrap_des {
    position: relative;
    float: left;
    width: 100%;
}

.kode_news_wrap figure {
    margin-bottom: 20px;
}

.kode_news_wrap figure:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
}

.kode_news_wrap figure img {
    width: 100%;
    height: auto;
}

.kode_news_wrap_des {
    padding: 5px 15px 23px;
    border-bottom: 5px solid;
}

.kode_news_wrap_des> span> a {
    display: block;
    margin-bottom: 17px;
    color: #929292;
    text-transform: uppercase;
}

.kode_news_wrap_des> h6> a {
    margin-bottom: 10px;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_news_wrap_des p {
    margin-bottom: 4px;
    color: #7e7e7e;
    word-break: break-all;
    font-weight: 500;
}

.kode_news_wrap_des> b> a {
    float: left;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_news_wrap_des> a {
    float: right;
    padding-top: 23px;
    color: #b1b1b1;
}

.kode_news_wrap:hover {
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);
}

.kode_news_wrap:hover figure:before {
    height: 100%;
}

/*
	  ==============================================================
		   NEWS WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   NEWS LIST WRAP CSS START
	  ==============================================================
*/
.kode_news_list {
    position: relative;
    float: left;
    margin-bottom: 30px;
    padding: 0 0 40px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}

.kode_news_list figure {
    width: 350px;
}

.kode_news_list figure img {
    width: 100%;
    height: auto;
}

.kode_news_list_des {
    float: none;
    padding: 10px 0 0 370px;
}

.kode_news_list_des span {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}

.kode_news_list_des> h6> a {
    display: block;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;
}

.kode_author {
    float: left;
    margin-bottom: 10px;
    color: #a1a1a1;
}

.kode_author> a {
    color: #a1a1a1;
}

.kode_news_list_des> ul> li {
    position: relative;
    float: left;
}

.kode_news_list_des> ul> li> a {
    padding: 0 8px;
    color: #a1a1a1;
}

.kode_news_list_des> ul> li> a> i {
    margin-right: 5px;
}

.kode_news_list_des p {
    float: left;
    color: #a1a1a1;
    word-break: break-all;
    line-height: 25px;
}

.kode_news_colmn_list.col-md-8 .kode_news_list:first-child {
    padding-top: 0;
}

.kode_news_colmn_list.col-md-8 .kode_news_list:last-child {
    padding-bottom: 20px;
    border-bottom: 0;
}

/*
	  ==============================================================
		   NEWS LIST WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   EVENT WRAP CSS START
	  ==============================================================
*/
.kode_event_grid_bg {
    padding-bottom: 50px;
}

.kode_event_wrap {
    position: relative;
    float: left;
    margin-bottom: 30px;
    width: 100%;
    border: 1px solid #ececec;
}

.kode_event_wrap figure {
    margin-bottom: 24px;
}

.kode_event_wrap figure:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    content: '';
    opacity: 0.7;
}

.kode_event_wrap figure img {
    width: 100%;
    height: auto;
}

.kode_event_wrap figure figcaption {
    position: absolute;
    top: 0;
    margin-top: -20px;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_event_wrap figure figcaption a {
    color: #fff;
    font-weight: bold;
    font-size: 25px;
}

.kode_event_des {
    float: left;
    padding: 0 15px 26px 15px;
    width: 100%;
}

.kode_event_des> h6> a {
    display: block;
    margin-bottom: 22px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.kode_event_des ul {
    position: relative;
    float: left;
    margin-bottom: 26px;
    padding-bottom: 18px;
    width: 100%;
    line-height: 12px;
}

.kode_event_des ul:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 2px;
    content: '';
}

.kode_event_des ul> li {
    position: relative;
    float: left;
}

.kode_event_des> ul> li> a {
    padding: 0 5px;
    color: #333;
    font-size: 12px;
}

.kode_event_des> ul> li> a> i {
    margin-right: 5px;
    font-size: 14px;
}

.kode_event_des ul li:first-child a {
    padding-left: 0;
}

.kode_event_des p {
    margin: 0 0 28px;
    color: #888;
    word-break: break-all;
    line-height: 27px;
}

.kode_event_des> a {
    display: inline-block;
    padding: 10px 30px;
    outline: 1px solid #ececec;
    border-left: 4px solid;
    color: #999;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}
/*Hover Style*/
.kode_event_wrap:hover {
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.kode_event_wrap:hover figure:before {
    width: 100%;
    height: 100%;
}

.kode_event_wrap:hover figure figcaption {
    top: 50%;
    opacity: 1;
}

/*
	  ==============================================================
		   EVENT WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   OUR FOCUS WRAP CSS START
	  ==============================================================
*/
.kode_focus_wrap {
    position: relative;
    float: left;
    padding-bottom: 35px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}

.kode_focus_wrap h2 {
    position: absolute;
    top: -25px;
    right: 0;
    color: #f7f7f7;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_focus_wrap h6 {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: normal;
}

.kode_focus_wrap span {
    display: block;
}

.kode_focus_wrap p {
    word-break: break-all;
}

.kode_focus_wrap a {
    display: inline-block;
    font-weight: bold;
}

.kode_focus_wrap a i {
    margin-left: 5px;
    vertical-align: middle;
}
/*
	  ==============================================================
		   OUR FOCUS WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   HOW IT WORK WRAP CSS START
	  ==============================================================
*/
.kode_services_video_bg {
    background-color: #f7f7f7;
}

.kode_service_video {
    margin: auto;
    width: 960px;
    text-align: center;
}

.kode_service_video p {
    margin-bottom: 35px;
    word-break: break-all;
}

.kode_video {
    position: relative;
    float: left;
    width: 100%;
}

.kode_video:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
}

.kode_video img {
    width: 100%;
    height: auto;
}

.kode_video_icon {
    position: absolute;
    top: 50%;
    z-index: 99999;
    margin-top: -10px;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_video_icon a {
    padding: 10px 20px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 20px;
}

.kode_video:hover .kode_video_icon {
    opacity: 1;
}
/*
	  ==============================================================
		   HOW IT WORK WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   ADVANCE SERVICES WRAP CSS START
	  ==============================================================
*/
.kode_advance_service {
    position: relative;
    float: left;
    width: 100%;
    border: 1px solid #ececec;
}

.kode_advance_service figure {
    opacity: 0;
}

.kode_advance_service figure img {
    width: 100%;
    height: auto;
}

.kode_advance_service figure:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    content: '';
    opacity: 0.7;
}

.kode_service_caption {
    position: absolute;
    bottom: -50%;
    z-index: -99999;
    float: left;
    padding: 0 20px;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.kode_service_caption p {
    margin-bottom: 20px;
    color: #fff;
    word-break: break-all;
    font-weight: bold;
}

.kode_service_caption a.kode_link_2:before {
    z-index: -9;
}

.kode_advance_hdg {
    position: absolute;
    top: 50%;
    float: left;
    margin-top: -55px;
    width: 100%;
    width: 100%;
    text-align: center;
}

.kode_advance_hdg i {
    margin-bottom: 15px;
    color: #666;
    font-size: 40px;
}

.kode_advance_hdg h6 {
    position: relative;
    padding-top: 15px;
    color: #666;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_advance_hdg h6:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 50px;
    height: 3px;
    background-color: #666;
    content: '';
}
/*Hover Style*/
.kode_advance_service:hover .kode_advance_hdg h6:before {
    background-color: #fff;
}

.kode_advance_service:hover figure {
    opacity: 1;
}

.kode_advance_service:hover figure:before {
    height: 100%;
}

.kode_advance_service:hover .kode_advance_hdg {
    top: 20px;
    margin: 0;
}

.kode_advance_service:hover .kode_advance_hdg i,
.kode_advance_service:hover .kode_advance_hdg h6 {
    color: #fff;
}

.kode_advance_service:hover .kode_service_caption {
    bottom: 5%;
    z-index: 9999;
    opacity: 1;
}

.kode_advance_service a:hover {
    color: #000;
}

/*
	  ==============================================================
		   WHY CHOOSE US WRAP CSS START
	  ==============================================================
*/
.kode_choose {
    position: relative;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.kode_choose i {
    position: absolute;
    top: 30px;
    left: 0;
    float: left;
    padding-left: 25px;
    color: #333;
    font-size: 20px;
}

.kode_choose span {
    display: inline-block;
    padding: 30px 0;
    color: #333;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_choose_des {
    float: left;
    padding: 20px;
    width: 100%;
    background-color: #3fb3e5;
}

.kode_choose_des i {
    float: left;
    width: 30px;
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.kdoe_choose_content {
    float: none;
    padding: 0 0 0 50px;
}

.kdoe_choose_content span {
    display: block;
    margin-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kdoe_choose_content p {
    color: #fff;
    word-break: break-all;
}
/*Tab Wrap Style*/
.kode_choose_tab {
    position: relative;
    float: left;
    width: 100%;
}

.kode_choose_tab ul {
    float: left;
    width: 100%;
}

.kode_choose_tab ul li {
    float: left;
    margin-left: 2px;
    width: 24.7%;
    border-radius: 3px;
}

.kode_choose_tab ul li a {
    display: block;
    padding: 18px 0;
    border: 1px solid #ececec;
    color: #a3a4a7;
    text-align: center;
    font-weight: bold;
}

.kode_choose_tab ul li:first-child {
    margin-left: 0;
}

.kode_choose_tab ul li.active a,
.kode_choose_tab ul li:hover a,
.kode_choose_tab ul li:focus a {
    border: 1px solid transparent;
    color: #333;
}

.kode_chose_tab_detail {
    float: left;
    margin-top: 2px;
    padding: 20px;
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 4px;
}

.kode_chose_tab_detail figure {
    float: left;
    margin-bottom: 30px;
    width: 112px;
}

.kode_chose_tab_detail figure img {
    width: 100%;
    height: auto;
}

.kode_tab_img_des {
    float: none;
    padding: 0 0 0 125px;
}

.kode_tab_img_des p {
    color: #a3a4a7;
    word-break: break-all;
}

.kode_chose_tab_detail p {
    float: left;
}

/*
	  ==============================================================
		   WHY CHOOSE US WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   TEAM WRAP CSS START
	  ==============================================================
*/
.kode-team-intro {
    position: relative;
    float: left;
    padding: 70px 0 50px;
    width: 100%;
}

.kode_background:before,
.kode_background:after {
    position: absolute;
    bottom: -56px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    content: '';
}

.kode_background:after {
    bottom: -51px;
}

.kode-team-testi {
    float: left;
    padding: 70px 0 40px;
    width: 100%;
    background: #f7f7f7;
}

.kode_team_img,
.kode_team_img figure img {
    float: left;
    width: 100%;
}

.kode_team_img figure {
    border-bottom: 3px solid;
}

.kode_team_img_des {
    float: left;
    width: 100%;
}

.kode_team_img_des h4 {
    margin-bottom: 7px;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}

.kode_team_img_des> p {
    margin-bottom: 20px;
    color: #666;
    word-break: break-all;
}

.kode_team_img_des span {
    display: inline-block;
    font-size: 16px;
}

.kode_team_img_des blockquote {
    position: relative;
    margin-bottom: 0;
    padding: 75px 100px;
    border: 0;
    background-color: #f4f4f4;
    text-align: left;
}

.kode_team_img_des blockquote p {
    position: relative;
    color: #666;
    font-size: 16px;
}

.kode_team_img_des blockquote p:before,
.kode_team_img_des blockquote p:after {
    position: absolute;
    top: -40px;
    left: -60px;
    color: #d3d3d3;
    content: '\f10d';
    font-size: 40px;
    font-family: fontawesome;
}

.kode_team_img_des blockquote p:after {
    top: auto;
    right: -30px;
    bottom: -42px;
    left: auto;
    content: '\f10e';
}

.kode_team_img_des blockquote:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 3px;
    height: 80%;
    background-color: #293f9b;
    content: '';
}

/*
	  ==============================================================
		   TEAM WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   BACKGROUND WRAP CSS START
	  ==============================================================
*/
.kode_background {
    position: relative;
}

.kode_bground_des {
    position: relative;
    float: left;
    padding: 43px 0 38px;
    width: 100%;
}

.kode_bground_des h4 {
    margin-bottom: 30px;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
}

.kode-team-bio .kode_bground_des h4 {
    margin-bottom: 10px;
}

.kode_bground_des p {
    color: #666;
    word-break: break-all;
}

.kode_bground_des:before,
.kode_bground_des:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    content: '';
}

.kode_bground_des:after {
    bottom: -5px;
}
/*
	  ==============================================================
		   BACKGROUND WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   EDUCATION WRAP CSS START
	  ==============================================================
*/
.kode_education,
.kode_edu_year,
.kode_edu_year ul,
.kode_edu_des,
.kode_edu_des ul {
    float: left;
    width: 100%;
}

.kode_education h4 {
    margin-bottom: 37px;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_edu_year {
    position: relative;
    text-align: center;
}

.kode_edu_year:before,
.kode_edu_year:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #e3e3e3;
    content: '';
}

.kode_edu_year:after {
    right: -5px;
}

.kode_edu_year ul li {
    float: left;
    padding: 40px 10px;
    width: 100%;
}

.kode_edu_year ul li h5 {
    display: inline-block;
    margin-bottom: 10px;
    padding: 15px;
    background: #f7f7f7;
    font-weight: bold;
}

.kode_edu_year ul li h6 {
    color: #293f9b;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
}

.kode_edu_des ul {
    padding: 0 20px;
}

.kode_edu_des ul li {
    float: left;
    padding: 30px 0;
    width: 100%;
    border-top: 1px solid #e4e4e4;
}

.kode_edu_des ul li figure {
    width: 260px;
    border: 2px solid #e4e4e4;
    border-radius: 3px;
}

.kode_edu_des ul li figure img {
    width: 100%;
    height: auto;
}

.kode_edu_img_des {
    float: none;
    padding: 0 0 0 280px;
}

.kode_edu_img_des h6 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_edu_img_des p {
    color: #666;
    word-break: break-all;
}

.kode_edu_des ul li:first-child {
    padding-top: 0;
    border-top: 0;
}
/*Even LI*/
.kode_edu_des ul li:nth-child(even) figure {
    float: right;
}

.kode_edu_des ul li:nth-child(even) .kode_edu_img_des {
    padding: 0 280px 0 0;
}

.kode-team-edu,
.kode-team-bio {
    float: left;
    width: 100%;
}

.kode-team-edu {
    margin-bottom: 4px;
}

.kode_education {
    padding: 42px 0 10px;
}

.kode_education:before,
.kode_education:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    content: '';
}

.kode_education:after {
    bottom: -4px;
}
/*
	  ==============================================================
		   EDUCATION WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   LATEST WORK WRAP CSS START
	  ==============================================================
*/
.kode_work_wrap,
.kode_work_des {
    position: relative;
    float: left;
    width: 100%;
}

.kode_work_wrap figure:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
            transition:         transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
            transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.kode_work_wrap figure img {
    width: 100%;
    height: auto;
    opacity: 0.95;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05, 1.05, 1);
            transform: scale3d(1.05, 1.05, 1);
}

.kode_work_hover {
    position: absolute;
    top: -50px;
    margin-top: -20px;
    width: 100%;
    text-align: center;
}

.kode_work_hover a {
    color: #fff;
    font-size: 24px;
}

.kode_work_des {
    padding: 30px 20px 22px;
    border: 1px solid #e4e4e4;
    text-align: center;
}

.kode_work_des h6 a {
    margin-bottom: 10px;
    color: #242424;
    text-transform: uppercase;
    font-weight: bold;
}

.kode_work_des p {
    color: #666;
    font-size: 16px;
    line-height: normal;
}

.kode_work_wrap:hover img {
    opacity: 0.3;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
}

.kode_work_wrap:hover figure:before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
            transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.kode_work_wrap:hover .kode_work_hover {
    top: 50%;
}

.kode_work_outr.owl-carousel .owl-wrapper-outer {
    padding-bottom: 30px;
}

.kode_work_outr .item {
    margin-right: 20px;
}

.kode_work_outr .owl-pagination {
    display: none;
}

.kode_work_outr.owl-theme .owl-controls .owl-buttons div {
    top: 50%;
    padding: 0;
    width: 36px;
    height: 36px;
    border-radius: 0;
    background-color: #fff;
    text-align: center;
    font-size: 0;
    line-height: 34px;
    opacity: 1;
}

.kode_work_outr .owl-buttons .owl-prev {
    position: absolute;
    left: -20px;
}

.kode_work_outr .owl-buttons .owl-next {
    position: absolute;
    right: -20px;
}

.kode_work_outr .owl-buttons .owl-prev:before,
.kode_work_outr .owl-buttons .owl-next:before {
    position: absolute;
    top: 0;
    left: 12px;
    color: #666;
    content: '\f104';
    font-size: 16px;
    font-family: fontawesome;
}

.kode_work_outr .owl-buttons .owl-next:before {
    right: 12px;
    left: auto;
    content: '\f105';
}

.kode_work_outr .owl-buttons .owl-prev:after {
    position: absolute;
    top: -7px;
    left: 4px;
    width: 0;
    height: 0;
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent #8a8a8a transparent;
    content: '';
    transform: rotate(131deg);
}

.kode_work_outr .owl-buttons .owl-next:after {
    position: absolute;
    top: -9px;
    left: 0;
    width: 0;
    height: 0;
    border-width: 8px 0 0 8px;
    border-style: solid;
    border-color: transparent transparent transparent #8a8a8a;
    content: '';
}

/*
	  ==============================================================
		   LATEST WORK WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   POLITICAL CAREER WRAP CSS START
	  ==============================================================
*/
.kf_political_career_bg {
    padding-bottom: 60px;
    background-color: #f3f3f3;
}

.kf_political_career,
.kf_year {
    position: relative;
    float: left;
    width: 100%;
}

.kf_political_career:before {
    position: absolute;
    top: 40px;
    bottom: 10px;
    left: 50%;
    width: 2px;
    background-color: #d8d8d8;
    content: '';
}

.kf_year {
    text-align: center;
}

.kf_year h5 {
    color: #1a1b1d;
    font-weight: bold;
}

.kf_empty {
    float: left;
    padding: 110px 0 0;
    width: 100%;
}
/*Right Side Wrap Style*/
.kf_monthly {
    position: relative;
    float: left;
    width: 100%;
}

.kf_month_right {
    position: relative;
    float: right;
    padding: 0 0 17px 30px;
    width: 50%;
}

.kf_month_right:before {
    position: absolute;
    top: 24px;
    left: -9px;
    width: 20px;
    height: 20px;
    border: 5px solid #ececec;
    border-radius: 100%;
    background-color: #9c9c9c;
    box-shadow: 0 0 0 10px #fff;
    content: '';
}

.kf_month_right h6,
.kf_month_left h6 {
    display: inline-block;
    margin: 0 0 30px;
    padding: 0 0 10px;
    border-bottom: 2px solid #d8d8d8;
    color: #ec6f75;
    text-transform: uppercase;
    font-size: 14px;
}

.kf_month_right h5,
.kf_month_left h5 {
    margin: 0 0 26px;
    color: #1a1b1d;
    text-transform: uppercase;
}

.kf_month_right p,
.kf_month_left p {
    word-break: break-all;
    font-size: 16px;
}
/*Left Side Wrap Style*/
.kf_month_left {
    position: relative;
    float: left;
    padding: 0 30px 17px 0;
    width: 50%;
    text-align: right;
}

.kf_month_left:before {
    position: absolute;
    top: 24px;
    right: -9px;
    width: 20px;
    height: 20px;
    border: 5px solid #ececec;
    border-radius: 100%;
    background-color: #9c9c9c;
    box-shadow: 0 0 0 10px #fff;
    content: '';
}

/*
	  ==============================================================
		   POLITICAL CAREER WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		  PERFORMANCE CHART WRAP CSS START
	  ==============================================================
*/
.kf_performance_bg {
    float: left;
    width: 100%;
}

.kf_chart {
    float: left;
    width: 100%;
}

.kf_chart #curve_chart {
    width: 100%;
    height: 250px;
}
/*
	  ==============================================================
		  PERFORMANCE CHART WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   YEARLY PERFORMANCE WRAP CSS START
	  ==============================================================
*/
.kode_yearly_performance,
.kode_yearly_performance ul {
    float: left;
    width: 100%;
}

.kode_yearly_performance ul li {
    position: relative;
    float: left;
    padding: 35px 20px 37px;
    width: 25%;
    text-align: center;
}

.kode_yearly_performance ul li h4 {
    margin-bottom: 5px;
    color: #fff;
    line-height: normal;
}

.kode_yearly_performance p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.kode_yearly_performance ul li:nth-child(odd) {
    background-color: #2b2b2b;
}

.kode_yearly_performance ul li:nth-child(even) {
    background-color: #353434;
}

/*
	  ==============================================================
		   YEARLY PERFORMANCE WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   EVENT DETAIL WRAP CSS START
	  ==============================================================
*/
.kode_event_detail_timer,
.kode_event_orgnizer,
.kode_summary,
.kode_event_share,
.kode_related_event {
    border-bottom: 1px solid #d3d3d3;
}

.kode_event_detail_timer {
    padding-bottom: 8px;
}

.kode_event_detail_timer,
.kode_event_timer_des,
.kode_event_orgnizer,
.kode_orgnizer_wrap,
.kode_summary,
.kode_event_share,
.kode_related_event,
.kode_related_comment,
.kode_leave_reply {
    float: left;
    width: 100%;
}

.kode_event_detail_timer figure {
    margin-bottom: 24px;
}

.kode_event_detail_timer figure img {
    width: 100%;
    height: auto;
}

.event_timer_date {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 120px;
    height: 120px;
    text-align: center;
}

.event_timer_date:before {
    position: absolute;
    left: 100%;
    width: 500%;
    height: 100%;
    border: solid 1px;
    content: '';
}

.event_timer_date:after {
    position: absolute;
    top: 0;
    right: 100%;
    width: 500%;
    height: 100%;
    border: solid 1px;
    content: '';
}

.kode_border_top {
    position: relative;
    float: left;
    width: 100%;
}

.kode_border_top:before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 500%;
    border: solid 1px;
    content: '';
}

.kode_border_top:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 500%;
    border: solid 1px;
    content: '';
}

.event_timer_date h2 {
    padding-top: 28px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 55px;
}

.event_timer_date span {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
}

.kode_event_timer_des p {
    margin-bottom: 17px;
    word-break: break-all;
}

.kode_event_timer_des h6 {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kode_timer {
    position: relative;
    float: left;
    padding: 8px 0 70px;
    width: 100%;
    background-image: url(images/timer-bg-01.jpg);
    background-position: center;
    background-size: cover;
}

.kode_timer .kode_counter {
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 85%;
}

.kode_timer:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.9;
}

.kode_counter {
    position: relative;
    top: 0;
    z-index: 9999;
    width: 100%;
    text-align: center;
}

.kode_counter h4 {
    margin-top: 34px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase;
}
/*Event Orgnizer Style*/
.kode_event_orgnizer,
.kode_summary,
.kode_related_event,
.kode_related_comment,
.kode_leave_reply {
    padding: 29px 0 23px;
}

.kode_event_share {
    padding: 20px 0 13px;
}

.kode_event_orgnizer h6,
.kode_summary h6,
.kode_related_event> h6,
.kode_related_comment> h6,
.kode_leave_reply h6 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.kode_leave_reply> h6,
.kode_related_comment> h6,
.kode_related_event> h6,
.kode_event_orgnizer> h6 {
    margin-bottom: 20px;
}

.kode_related_event {
    padding: 29px 0 26px;
}

.kode_orgnizer_wrap figure {
    width: 110px;
    border-bottom: 2px solid;
}

.kode_orgnizer_wrap figure img {
    width: 100%;
    height: auto;
}

.kode_orgnizer_des {
    float: none;
    padding: 0 0 0 130px;
}

.kode_orgnizer_des h6 {
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: normal;
}

.kode_orgnizer_des h6 span {
    margin-left: 5px;
    color: #999;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

ul.kode_orgnizer_email,
ul.kode_orgnizer_social {
    float: left;
    width: 100%;
    line-height: normal;
}

ul.kode_orgnizer_email {
    margin-bottom: 2px;
}

.kode_orgnizer_email li {
    float: left;
    margin-bottom: 10px;
    width: 100%;
    color: #999;
}

.kode_orgnizer_email li i {
    margin-right: 10px;
}

ul.kode_orgnizer_social {
    margin-bottom: 10px;
}

.kode_orgnizer_social li {
    position: relative;
    float: left;
}

.kode_orgnizer_social li a {
    padding: 0 10px;
    color: #999;
}

.kode_orgnizer_social li:first-child a {
    padding-left: 0;
}
/*Summary Wrap Style*/
.kode_summary ul li {
    position: relative;
    float: left;
    padding: 8px 0 8px 35px;
    width: 100%;
}

.kode_summary ul li:before {
    position: absolute;
    top: 8px;
    left: 0;
    content: '\f00c';
    font-size: 16px;
    font-family: fontawesome;
}

.kode_summary ul li a {
    display: block;
    line-height: normal;
}
/*Event Share Style*/
.kode_event_share {
    padding-left: 20px;
}

.kode_event_share ul li {
    position: relative;
    float: left;
}

.kode_event_share ul li a {
    display: block;
    padding: 0 10px 4px;
    color: #999;
    text-transform: capitalize;
}
/*Related Event Style*/
.kode_event_list {
    position: relative;
    float: left;
    width: 100%;
}

.kode_event_list figure {
    margin-bottom: 14px;
}

.kode_event_list figure img {
    width: 100%;
    height: auto;
}

.kode_event_list> h6> a {
    display: block;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-size: 16px;
}

.kode_event_list ul {
    float: left;
    width: 100%;
}

.kode_event_list ul> li {
    position: relative;
    float: left;
}

.kode_event_list ul> li> a {
    padding: 0 10px 6px;
    color: #999;
    font-size: 12px;
}

.kode_event_list ul> li> a> i {
    margin-right: 5px;
}

.kode_event_list ul li:first-child a {
    padding-left: 0;
}

.kode_event_list a {
    margin-bottom: 5px;
    padding-right: 10px;
    color: #000;
    word-break: break-all;
    line-height: 20px;
}
/*Pagination Wrap Style*/
.kf_pagination {
    padding: 20px 0 19px;
    border-bottom: 1px solid #d3d3d3;
}

.kf_pagination,
.kf_pagination ul {
    float: left;
    margin: 0;
    width: 100%;
}

.kf_pagination ul li a {
    border: 0;
    color: #222;
    font-size: 16px;
}

.kf_pagination ul li:hover a {
    background: none;
}
/*Related Comment Style*/
.kode_related_comment {
    padding-bottom: 18px;
}

.kode_related_comment ul {
    float: left;
    width: 100%;
}

.kode_related_comment ul li {
    position: relative;
    float: left;
    padding: 35px 0 0;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
}

.kode_related_comment ul li ul {
    border-top: 1px solid #d3d3d3;
}

.kode_related_comment> ul> li:first-child {
    padding-top: 0;
}

.kode_related_comment ul li figure {
    width: 65px;
    border-bottom: 1px solid;
}

.kode_related_comment ul li figure img {
    width: 100%;
    height: auto;
}

.kode_related_comment ul> li> .kode_comment_des p {
    margin-bottom: 30px;
}

.kode_comment_des {
    float: none;
    padding: 0 0 0 90px;
}

.kode_comment_author {
    float: left;
    margin-bottom: 10px;
}

.kode_comment_author h6 {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.kode_comment_author span {
    display: inline-block;
    margin-left: 10px;
    color: #909090;
}

.kode_comment_des a {
    display: inline-block;
    float: right;
    text-align: right;
}

.kode_comment_des a i {
    margin-right: 5px;
}

.kode_comment_des a:hover {
    color: #000;
}

.kode_comment_des> p {
    float: left;
    margin-bottom: 10px;
    width: 100%;
}

.kode_related_comment ul ul {
    padding-left: 70px;
}

.kode_related_comment ul ul li {
    padding-bottom: 0;
}

.kode_related_comment ul li:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}
/*Leave A Reply Style*/
.kode_leave_reply {
    padding-bottom: 8px;
}

.kode_reply_field {
    float: left;
    margin-bottom: 25px;
    width: 100%;
}

.kode_reply_field input[type='text'] {
    padding: 8px 15px;
    width: 100%;
    height: 45px;
    border: 1px solid #ddd;
    color: #939393;
}

.kode_reply_field textarea {
    padding: 15px 15px;
    width: 100%;
    height: 235px;
    border: 1px solid #ddd;
    color: #939393;
    resize: none;
}

.kode_reply_field button {
    margin-bottom: 13px;
}
/*
	  ==============================================================
		   EVENT DETAIL WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   NEWS DETAIL WRAP CSS START
	  ==============================================================
*/
.kode_news_detail {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.kode_news_detail figure {
    margin-bottom: 10px;
    border-bottom: 5px solid;
}

.kode_news_detail figure img {
    width: 100%;
    height: auto;
}

.kode_news_detail h4 {
    margin-bottom: 10px;
    font-weight: bold;
}

.kode_news_detail> ul {
    float: left;
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.kode_news_detail> ul> li {
    float: left;
    padding: 0 10px;
}

.kode_news_detail> ul> li> a {
    color: #a1a1a1;
}

.kode_news_detail ul li i {
    margin-right: 10px;
}

.kode_news_detail ul li:first-child {
    padding-left: 0;
}

.kode_news_detail p {
    margin-bottom: 10px;
    word-break: break-all;
}

.kode_news_detail blockquote {
    margin-bottom: 10px;
    padding: 50px 70px;
    border-left: 2px solid;
    background: #f7f7f7;
}

.kode_news_detail blockquote p {
    position: relative;
    color: #898989;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
}

.kode_news_detail blockquote p:before,
.kode_news_detail blockquote p:after {
    position: absolute;
    top: -30px;
    left: -20px;
    content: '\f10d';
    font-size: 22px;
    font-family: fontawesome;
}

.kode_news_detail blockquote p:after {
    top: auto;
    right: 0;
    bottom: -30px;
    left: auto;
    content: '\f10e';
}


/*
	  ==============================================================
		   NEWS DETAIL WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   CONTACT US WRAP CSS START
	  ==============================================================
*/
.kf_contactus {
    position: relative;
    float: left;
    width: 100%;
}

.kf_contact_map {
    float: left;
    width: 100%;
}

.kf_contact_map .map-canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
}
/*Office Location Wrap Style*/
.kf_location_wrap {
    position: relative;
    margin: auto;
    padding: 70px 50px 55px;
    width: 960px;
    background: #fff;
}

ul.kf_office_name {
    float: left;
    margin: 0 0 50px;
    width: 100%;
    text-align: center;
}

.kf_office_name li {
    position: relative;
    display: inline-block;
    margin-left: -4px;
    width: 30%;
    border: 4px solid;
}

.kf_office_name li a {
    display: block;
    padding: 18px 0;
    color: #333236;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
}

.kf_office_name li.active a,
.kf_office_name li:hover a,
.kf_office_name li:focus a {
    color: #fff;
}
/*Office Location Info Wrap Style*/
.kf_location_info {
    float: left;
    width: 100%;
    text-align: center;
}

.kf_location_info> i {
    margin-bottom: 22px;
    width: 70px;
    height: 70px;
    border: 4px solid #d2d2d3;
    border-radius: 100%;
    background-color: #1f1e22;
    color: #fff;
    font-size: 30px;
    line-height: 65px;
}

.kf_location_info h6 {
    margin: 0 0 14px;
    color: #333236;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.kf_location_info p,
.kf_location_info span,
.kf_location_info a {
    margin: 0;
    color: #333236;
    text-transform: uppercase;
}

.kf_location_info a {
    display: inline-block;
    margin-bottom: 10px;
}

.kf_location_info span {
    display: block;
}

ul.kf_loc_socil_icon {
    float: left;
    width: 100%;
}

.kf_loc_socil_icon li {
    position: relative;
    display: inline-block;
}

.kf_loc_socil_icon li a {
    display: block;
    padding: 0 8px;
    color: #1f1e22;
    font-size: 16px;
}
/*Hover Style*/
.kf_location_info:hover i {
    background-color: #fff;
}

/*
	  ==============================================================
		   CONTACT US WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   GET IN TOUCH WRAP CSS START
	  ==============================================================
*/
.kf_touch_field {
    position: relative;
    float: left;
    width: 100%;
}

.kf_touch_field input[type='text'],
.kf_touch_field textarea {
    margin-bottom: 30px;
    padding: 8px 15px;
    width: 100%;
    height: 50px;
    border: 1px solid #e5e6e9;
    color: #27282d;
    color: #27282d;
}

.kf_touch_field textarea {
    height: 150px;
}

.kf_touch_field input[type='submit'],
.kf_touch_field button {
    float: right;
}

.kf_touch_field button.kode_btn_1 {
    padding: 12px 40px;
    border: 1px solid #000;
    color: #474747;
}

.kf_touch_field button.kode_btn_1:before {
    background-color: #474747;
}

.kf_touch_field button.kode_btn_1:hover {
    color: #fff;
}
/*Get Touch Wrap Image*/
.kf_touch_img {
    float: left;
    margin-top: -80px;
    width: 100%;
}

.kf_touch_img img {
    width: 100%;
    height: auto;
}

/*
	  ==============================================================
		   GET IN TOUCH WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   404 PAGE WRAP CSS START
	  ==============================================================
*/
.kf_404_bg {
    padding-bottom: 60px;
}

.kf_404_wrap {
    margin: auto;
    width: 685px;
    text-align: center;
}

.kf_404_wrap h2 {
    margin-top: -4px;
    color: #333;
    font-weight: bold;
    font-size: 100px;
    line-height: 85px;
}

.kf_404_wrap h2 span {
    display: inline-block;
    padding: 0 5px;
    font-weight: bold;
    font-size: 80px;
}

.kf_404_wrap h4 {
    position: relative;
    margin: 0 0 40px;
    text-transform: uppercase;
    font-weight: bold;
}

.kf_404_wrap h4:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 50px;
    height: 2px;
    content: '';
}

.kf_404_wrap h5 {
    margin-bottom: 32px;
    color: #16283c;
    text-transform: uppercase;
}

.kf_404_wrap h5 span {
    display: block;
}

.kf_404_wrap p {
    margin-bottom: 43px;
    color: #16283c;
    text-transform: uppercase;
}

.kf_404_form {
    position: relative;
    margin: auto auto 43px;
    width: 535px;
    height: 55px;
    border: 1px solid #c8c8c8;
}

.kf_404_form input[type='text'] {
    padding: 17px 60px 16px 40px;
    width: 100%;
    border: 0;
    color: #888;
}

.kf_404_form button,
.kf_404_form input[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 25px;
    height: 53px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
}

.kf_404_form button:hover,
.kf_404_form input[type='submit']:hover {
    background-color: #020202;
}

.kf_404_form i {
    position: absolute;
    top: 19px;
    left: 10px;
    color: #b2b2b2;
    font-size: 16px;
}

.kf_page_list,
.kf_page_list ul {
    float: left;
    width: 100%;
}

.kf_page_list p {
    color: #666;
    text-transform: uppercase;
    font-size: 15px;
}

.kf_page_list ul {
    padding-left: 20px;
}

.kf_page_list ul li {
    position: relative;
    float: left;
    width: 25%;
}

.kf_page_list ul li:before {
    position: absolute;
    top: 6px;
    left: 0;
    color: #666;
    content: '\f105';
    font-size: 16px;
    font-family: fontawesome;
}

.kf_page_list ul li a {
    display: block;
    padding: 8px 16px;
    color: #666;
    text-align: left;
    text-transform: capitalize;
}

/*
	  ==============================================================
		   404 PAGE WRAP CSS END
	  ==============================================================
*/

/*
	  ==============================================================
		   COMMING SOON PAGE WRAP CSS START
	  ==============================================================
*/
.kf_comming_bg {
    position: relative;
    padding-bottom: 66px;
    background-image: url(images/comeing-soon.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.kf_comming_wrap,
.kf_comming_counter {
    float: left;
    width: 100%;
    text-align: center;
}

.kf_comming_counter {
    margin-bottom: 50px;
}

.kf_comming_wrap a {
    display: inline-block;
    margin-bottom: 25px;
}

.kf_comming_counter h2 {
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kf_comming_counter h4 {
    margin-bottom: 42px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.kf_comming_counter ul.countdown:before,
.kf_comming_counter ul.countdown:after {
    display: none;
}

.kf_comming_counter ul.countdown li {
    position: relative;
    margin-left: 15px;
    width: 100px;
    width: 150px;
    height: 10px;
    height: 150px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 60px;
}

.kf_comming_counter ul.countdown li span {
    padding-top: 10px;
    border: 0;
    background: none;
}

.kf_comming_counter ul.countdown li p {
    position: absolute;
    right: 0;
    bottom: 44px;
    left: 0;
    margin: auto;
    color: #020202;
}

.kf_comming_form {
    position: relative;
    margin: auto auto 50px;
    width: 560px;
}

.kf_comming_form form input[type='text'] {
    float: left;
    padding: 8px 15px;
    width: 370px;
    height: 50px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
}

.kf_comming_form button,
.kf_comming_form input[type='submit'] {
    float: right;
    padding: 0 25px;
    height: 50px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    text-transform: uppercase;
}

.kf_comming_form button:hover,
.kf_comming_form input[type='submit']:hover {
    color: #fff;
}

.kf_comming_social {
    margin-top: 50px;
}

.kf_comming_social,
.kf_comming_social ul {
    float: left;
    width: 100%;
    text-align: center;
}

.kf_comming_social ul li {
    position: relative;
    display: inline-block;
}

.kf_comming_social ul li a {
    display: block;
    padding: 0 15px;
    color: #fff;
    font-size: 18px;
}

/*
	  ==============================================================
		   COMMING SOON PAGE WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   TEAM LISTING WRAP CSS END
	  ==============================================================
*/
.kf_team_listing_bg {
    padding-bottom: 50px;
}

.kode_politician {
    margin-bottom: 30px;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.18);
}

.kode_politician:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.18);
}
/*
	  ==============================================================
		   TEAM LISTING WRAP CSS END
	  ==============================================================
*/


/*
	  ==============================================================
		   SHORTCODE PAGE WRAP CSS START
	  ==============================================================
*/
.kf_shortcode_bg {
    padding-top: 57px;
}

.kode_shortcode_wrap,
.kode_shortcode_inner_wrap {
    position: relative;
    float: left;
    padding-bottom: 50px;
    width: 100%;
}

.kode_shortcode_wrap:before,
.kode_shortcode_inner_wrap:before {
    position: absolute;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #e1e1e1;
    content: '';
}

.kode_shortcode_heading {
    float: left;
    padding-bottom: 30px;
    background: none;
}

.kode_shortcode_heading h4 {
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    text-transform: uppercase;
    font-weight: bold;
}

.kf_hdg_style {
    float: left;
    width: 100%;
}
/*Heading Wrap Style*/
.kode_shortcode_inner_wrap {
    padding-top: 50px;
}

.kode_shortcode_para,
.kode_shortcode_social,
.kode_shortcode_btn {
    float: left;
    width: 100%;
}

.kode_shortcode_inner_wrap .kode_hdg_2,
.kode_shortcode_inner_wrap .kode_hdg_3 {
    padding: 20px 0;
    background-color: #000;
}
/*Heading Wrap Style ends*/


/*Blockquotes Style*/
.kode_shortcode_para p {
    color: #666;
}

.kode_shortcode_inner_wrap .kode_news_detail {
    border-bottom: 0;
}

/*Socail Icon 1*/
.kode_shortcode_social> ul {
    padding-bottom: 25px;
}

.kode_shortcode_social> ul> li {
    display: inline-block;
}

.kode_shortcode_social> ul> li> a {
    padding: 0 20px;
    color: #666;
    font-size: 24px;
}

.kode_shortcode_social> ul> li:first-child a {
    padding: 0;
}
/*Icon Style 2*/
.kode_shortcode_inner_wrap .kode_scl_icon {
    float: left;
    width: 100%;
}

.kode_shortcode_inner_wrap .kode_scl_icon ul li a {
    color: #000;
}

.kode_shortcode_inner_wrap .kode_scl_icon ul li.active a,
.kode_shortcode_inner_wrap .kode_scl_icon ul li:hover a {
    color: #fff;
}

/*Socail Icon 1 ends*/

/*Link Style*/
.kf_btn_list {
    float: left;
    padding: 20px;
    width: 100%;
    background-color: #000;
}

.kf_btn_list a {
    margin: 20px;
}

/*Link Style ends*/

/*Skill Style*/
.kode_shortcode_inner_wrap .kode-skills {
    background-color: #000;
}
/*
	  ==============================================================
		   SHORTCODE PAGE WRAP CSS START
	  ==============================================================
*/
.dl-menuwrapper button.dl-active:after {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}

.kode_event_des ul li a {
    font-size: 14px;
    line-height: 25px;
}

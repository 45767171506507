/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
	color:#fff;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
    position: absolute;
    text-align: center;
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
    margin: 0px;
    padding: 0px 0px 3px 0;
    text-align: center;
	color:#fff;
	font-size:24px !important;	
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 300%;
    margin-top: 0.1em;
	font-size:40px;
}

.time_circles h4 {
    left: 0;
    position: relative;
    right: 0;
    top: 110px;
}

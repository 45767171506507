/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Normalize
@import 'vendor/normalize';
@import 'vendor/_columns';

//Vendor
@import 'template/bootstrap.min.scss';
@import 'template/fullcalendar.scss';
@import 'template/jquery.bxslider.scss';
@import 'template/owl.carousel.scss';
@import 'template/font-awesome.min.scss';
@import 'template/prettyPhoto.scss';
@import 'template/svg-style.scss';
@import 'template/widget.scss';
@import 'template/typography.scss';
@import 'template/TimeCircles.scss';
@import 'template/shortcodes.scss';
@import 'template/component.scss';
@import 'template/style.scss';
@import 'template/color.scss';
@import 'template/responsive.scss';

//LRS
@import 'forms.scss';
@import 'modal.scss';
@import 'Sherman.scss';
